import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const ORIGIN_KEY = 'auth-social-origin';
const FIRST_VISIT = 'FIRSTS-VISIT';
const SOCIAL_USER_EMAIL_KEY='auth-social-user-email';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  public signOut(): void {
    localStorage.clear();
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveSocialOrigin(socialOrigin: string): void {
    localStorage.removeItem(ORIGIN_KEY);
    localStorage.setItem(ORIGIN_KEY, socialOrigin);
  }

  public saveFirstVisitToHomePageAfterLogin(data:string): void {
    localStorage.removeItem(FIRST_VISIT);
    localStorage.setItem(FIRST_VISIT, data);
  }

  public getFirstVisitToHomePageAfterLogin(): string | null {
   return localStorage.getItem(FIRST_VISIT);
  }

  public getSocialOrigin(): string | null {
    return localStorage.getItem(ORIGIN_KEY);
  }

  public saveSocialUserEmail(email: string): void {
    localStorage.removeItem(SOCIAL_USER_EMAIL_KEY);
    localStorage.setItem(SOCIAL_USER_EMAIL_KEY, email);
  }

  public getSocialUserEmail(): string | null {
    return localStorage.getItem(SOCIAL_USER_EMAIL_KEY);
  } 
  public saveUser(user: any): void {
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }
}
