import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { concatMap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class ChatService {
	token = '';
	usertoken: any = [];
	key = '';
	defaultGroupIcon: string = 'https://static.turbosquid.com/Preview/001292/481/WV/_D.jpg';
	constructor(private tokenStorage: TokenStorageService,
		private http: HttpClient) {
		this.usertoken = JSON.parse(this.tokenStorage.getToken());
		if (this.usertoken && this.usertoken.access_token) {
			this.token = this.usertoken.access_token;
		}
	}

	initiateChat(candidateJobDetail,payload){
		let client=candidateJobDetail.client;
		let candidate=candidateJobDetail.candidate;
		let roomName=candidateJobDetail.jobAssignments.title;
		let chatRoomData = { "roomName": roomName, "uniqueIdentifier": null, "roomIcon": this.defaultGroupIcon, "memberIds": [] };
		let memberIds=[];
		let clientRequest={
			"firstName":client.firstName,
			"lastName":client.lastName,
			"email":client.email,
			"profilePic":client.profilePic,
			"userType":"Admin",
			"clientEmail":client.email
		};
		let candidateRequest={
			"firstName":candidate.firstName,
			"lastName":candidate.lastName,
			"email":candidate.email,
			"profilePic":candidate.profilePic,
			"userType":"User",
			"clientEmail":candidate.email
		};
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		if(payload=='Success'){
			return this.http.post(`${environment.apiChatUrl}/member/detail`, JSON.stringify(clientRequest), {
				headers: headers
			})
				.pipe(
					concatMap(
						(res) =>
							this.storeCandidateInChatApp(res,memberIds,candidateRequest,headers)),
							concatMap(
								(res1) => this.createChatRoom(res1,memberIds,chatRoomData,headers)));
		}else if(payload=='Already Initiated'){
			window.open("http://localhost:4200/chat/"+this.token, "_blank");
			return null;
		}
		
	}

	storeCandidateInChatApp(res,memberIds,candidateRequest,headers){
		memberIds.push(res['payload']['id']);
		return this.http.post(`${environment.apiChatUrl}/member/detail`, JSON.stringify(candidateRequest), { headers: headers });
	}

	createChatRoom(res1,memberIds,chatRoomData,headers){
		memberIds.push(res1['payload']['id']);
		chatRoomData.memberIds=memberIds;
		return this.http.post(`${environment.apiChatUrl}/chat/room`, JSON.stringify(chatRoomData), { headers: headers });
	}
	
}
