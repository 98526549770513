<div class="card" *ngIf="cardData.type !== 'viewAll'; else viewAllCard">
  <div class="row col-12" (click)="viewDetails($event)">
    <img *ngIf="cardData?.projectPlanImage?.length > 0; else fallbackImage" 
         [src]="cardData.projectPlanImage[0].url" 
         class="Images">
    
    <ng-template #fallbackImage>
      <img src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg" class="Images">
    </ng-template>
  </div>
  <div class="row col-12" style="margin-top: 15px;" (click)="viewDetails($event)">
    <div class="col-md-12 d-flex flex-column justify-content-start">
      <span class="title">{{ cardData.title | capitalize }}</span>
      <!-- <span class="target">{{ cardData.description | stripHtml }}</span> -->
    </div>
  </div>
  <div class="row col-12">
    <div class="col-md-12 d-flex flex-column justify-content-start" matTooltip="{{cardData.description | stripHtml}}">
      <span class="-text" [innerHTML]="(cardData.description | truncateWords:12)"></span>
    </div>
  </div>
  <div class="row col-12">
    <div class="col-md-12 d-flex flex-column justify-content-start">
      <span class="title"> ₹{{ formatPrice(cardData.fromPrice) }} - {{ formatPrice(cardData.toPrice) }}</span>
    </div>
  </div>
  <div class="col-md-12">
    <div>{{ cardData.details }}</div>
    <div class="target">{{ cardData.cityName }} &nbsp;{{ cardData.stateName }}</div>
  </div>
  <div class="col-md-12">
    <span class="title">{{ cardData.price }}</span>
  </div>
  <!-- <div class="col-md-12 flex-column">
    <button class="contact">Contact</button>
  </div> -->
</div>

<ng-template #viewAllCard>
  <div class="view-all-card" (click)="navigateTOListing()">
    <mat-icon class="view-all-icon">arrow_forward</mat-icon> 
    <span >View All</span>
  </div>
</ng-template>
