import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProfileService } from '../_services/profile.service';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-profileupdate',
  templateUrl: './profileupdate.component.html',
  styleUrls: ['./profileupdate.component.scss']
})
export class ProfileUpdateComponent implements OnInit {
  profilePic: string = '../assets/images/profile.png';
  updateform: FormGroup;
  submitted = false;
  isreadonly: boolean = false;
	aciveforgot = false;

  constructor(private formBuilder: FormBuilder, private profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.populateForm();
  }

  initializeForm() {
    this.updateform = this.formBuilder.group({
      fullName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required]],
      password:[''],
      userType: [null], // Initialize userType
      deleteAccount: [0],
      accountEnabled: [1]
    });
  }
	forgotSwitch() {
		this.aciveforgot = true;
	}
  navigateToReset() {
    const email = this.updateform.get('email')?.value;
    this.router.navigate(['/resetpassword'], { queryParams: { email } });
  }
  populateForm() {
    const profileData = localStorage.getItem('UserProfile');
    // console.log('profileData', profileData);

    if (profileData) {
      const parsedProfileData = JSON.parse(profileData);
      console.log('parsedProfileData',parsedProfileData);
      

      // Determine which user type should be selected
      let selectedUserType: string | null = null;
      if (parsedProfileData.payload.builder) {
        selectedUserType = 'builder';
      } else if (parsedProfileData.payload.agent) {
        selectedUserType = 'agent';
      } else if (parsedProfileData.individual) {
        selectedUserType = 'individual';
      }

      // Patch the form with profile data
      this.updateform.patchValue({
        fullName: parsedProfileData.payload.fullName,
        email: parsedProfileData.payload.email,
        mobileNumber: parsedProfileData.payload.mobileNumber,
        password: parsedProfileData.payload.password,
        userType: selectedUserType, // Set the selected user type
        deleteAccount: parsedProfileData.deleteAccount || 0,
        accountEnabled: parsedProfileData.accountEnabled || 1
      });
    }
  }

  get f() { return this.updateform.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.updateform.invalid) {
      return;
    }

    const payload = this.updateform.value;
    console.log("payload before cleaning", payload);

    // Only include the selected userType in the payload
    const userType = payload.userType;
    const userTypePayload = userType ? { [userType]: true } : {};

    // Remove userType from the payload
    delete payload.userType;
    delete payload.password;

    // Merge the userTypePayload with the existing payload
    const finalPayload = {
      ...payload,
      ...userTypePayload
    };

    console.log("payload after cleaning", finalPayload);
    localStorage.setItem('profileData', JSON.stringify(finalPayload));
    this.updateprofile(finalPayload);
  }

  updateprofile(payload: any) {
    this.profileService.updateprofile(payload).subscribe(
      response => {
        if (response['status'] === 'SUCCESS') {
          console.log('Profile updated successfully!');

          // Update local storage with new profile data
          const updatedProfileData = {
            ...JSON.parse(localStorage.getItem('auth-user')),
            ...payload
          };
          localStorage.setItem('auth-user', JSON.stringify(updatedProfileData));

          Swal.fire({
            icon: 'success',
            title: 'Profile updated successfully!',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });

        } else {
          Swal.fire({
            icon: 'error',
            title: response['message'],
            showConfirmButton: false,
            timer: environmentbasic.timer
          });
          console.error('Profile update failed:', response['message']);
        }
      },
      error => {
        console.error('Error updating profile:', error);
      }
    );
  }
}
