import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { JobService } from '../_services/job.service';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import { HomeService } from '../_services/home.service';
import {environmentbasic} from '../../environments/environment-basic';
@Component({
	selector: 'app-search-assignments',
	templateUrl: './search-assignments.component.html',
	styleUrls: ['./search-assignments.component.css']
})
export class SearchAssignmentComponent implements OnInit {
	activetab = 'applied';
	constructor(
		private jobService: JobService,
		private homeService: HomeService,
		private tokenStorage: TokenStorageService,
		private route: ActivatedRoute,
		private router: Router) { }

	page = 1;
	count = 0;
	tableSize = 2;
	assignments: any = [];
	skills: any = [];
	jobs:any=[];
	searchString:string='';
	userinfo:any=[];
	ngOnInit(): void {
		AOS.init();
		this.searchString = this.route.snapshot.paramMap.get("se");
		this.userinfo = JSON.parse(this.tokenStorage.getUser());
		this.homeService.getSkills().subscribe(res => {
			if (res['status'] == 'SUCCESS') {
				if (res['payload']['skills'] && res['payload']['skills'].length > 0) {
					this.skills = res['payload']['skills'];
				}
			}

		});
		this.jobService.searchJob(this.searchString,this.page,this.tableSize).subscribe(response => {
			if (response['status'] == 'SUCCESS') {
				if (response['payload']['jobs'] && response['payload']['jobs'].length > 0) {
					this.jobs = response['payload']['jobs'];
					this.count = response['payload']['total'];
					this.tableSize = response['payload']['limit'];
					this.page = response['payload']['pageNumber'];
				}
			}

		});

	}
	getSkillById(id){
		for (var i = 0; i < this.skills.length; i++) {
			if (this.skills[i]['id'] == id) {
				return(this.skills[i]['name']);
			}
		}
		return '';
	}
	saveJob(jobId){
		this.jobService.likedJob(jobId,this.userinfo.id).subscribe(
			response => {
				if (response['status'] == 'SUCCESS') {
					Swal.fire({
						icon: 'success',
						title: 'Job Saved successfully.',
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
					window.location.reload();
				} else {
					Swal.fire({
						icon: 'error',
						title: response['message'],
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
				}
			});
	}

	onTableDataChange(event) {
		this.page = event;
		this.jobService.searchJob(this.searchString,this.page,this.tableSize).subscribe(response => {
			if (response['status'] == 'SUCCESS') {
				if (response['payload']['jobs'] && response['payload']['jobs'].length > 0) {
					this.jobs = response['payload']['jobs'];
					this.count = response['payload']['total'];
					this.tableSize = response['payload']['limit'];
					this.page = response['payload']['pageNumber'];
				}
			}

		});
	}
	searchJob(searchkey) {
		this.jobs = [];
		console.log("searchkey value" + searchkey.value);
		this.searchString=searchkey.value;
		this.jobService.searchJob(this.searchString,this.page,this.tableSize).subscribe(response => {
			if (response['status'] == 'SUCCESS') {
				if (response['payload']['jobs'] && response['payload']['jobs'].length > 0) {
					this.jobs = response['payload']['jobs'];
					this.count = response['payload']['total'];
					this.tableSize = response['payload']['limit'];
					this.page = response['payload']['pageNumber'];
				}
			}

		});
	}

	displayTab(tabid) {
		this.activetab = tabid;
	}

}
