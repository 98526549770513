import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-map-selector',
  templateUrl: './map-selector.component.html',
  styleUrls: ['./map-selector.component.scss']
})
export class MapSelectorComponent implements OnInit {
  center: google.maps.LatLngLiteral;
  zoom = 15;
  @Output() locationSelected = new EventEmitter<{ lat: number, lng: number }>();

  constructor() { }

  ngOnInit(): void {
    // Set default location to the user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    }
  }

  mapClicked(event: google.maps.MapMouseEvent) {
    if (event.latLng) {
      this.center = event.latLng.toJSON();
      this.locationSelected.emit(this.center);
    }
  }}
