import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-contact-seller',
  templateUrl: './contact-seller.component.html',
  styleUrls: ['./contact-seller.component.scss']
})
export class ContactSellerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ContactSellerComponent>,
  ) { }
  closeDialog() {
    this.dialogRef.close();
  }
  
  ngOnInit(): void {
  }

}
