import { Component } from '@angular/core';
import { LoadingService } from './_services/loading.service';
import { Observable } from 'rxjs';
import { SlideInterface } from './image-slider/slide-interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  loading$: Observable<boolean>;
  title = 'Ksa-professionals-fe';
  constructor(private loadingService: LoadingService) {
    this.loading$ = this.loadingService.loading$;
  }
  slides: SlideInterface[] = [
    { url: '/assets/image-1.jpeg', title: 'beach' },
    { url: '/assets/image-2.jpeg', title: 'boat' },
    { url: '/assets/image-3.jpeg', title: 'forest' },
    { url: '/assets/image-4.jpeg', title: 'city' },
    { url: '/assets/image-5.jpeg', title: 'italy' },
  ];
}
