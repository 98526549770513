import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskMobileEmail'
})
export class MaskMobileEmailPipe implements PipeTransform {

  transform(value: string, type: string): string {
    if (type === 'mobile') {
      return '+91 ' + value.substring(0, 2) + '***' + value.substring(5, 8) + '**';
    } else if (type === 'email') {
      const emailParts = value.split('@');
      return emailParts[0][0] + '*****' + emailParts[0].slice(-1) + '@*****.com';
    }
    return value;
  }

}
