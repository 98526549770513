<div class="card" >    
  <div class="carousel-container">
    <ng-container *ngIf="Images.length > 0; else noImage">
      <app-image-slide [slides]="Images"></app-image-slide>
    </ng-container>
    <ng-template #noImage>
      <app-image-slide [slides]="['https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg']"></app-image-slide>
    </ng-template>
  </div>
  <div class="card-body">
    <div class="listing-header">
      <h5 style="font-weight: bold; cursor: pointer;" (click)="navigateToDetail(listing.id)">
         {{ listing.title | capitalize }}
       </h5>
       
       <span class="badge" style="font-weight: bold; font-size: 12px;">{{ listing?.feature?.transactionType }}</span>

    </div>
    <div class="listing-subheader">
      <h3><span style="color: #000000; font-size: 18px;">{{listing.noOfBadroom}} Bedroom House</span><span style="font-size: 18px;"> in {{listing.area}},{{listing.cityName}}</span></h3>
    </div>
    <div class="listing-details">
       <!-- <div class="price">
         <span style="font-size: 22px; font-weight: bold;">{{ listing?.asking | formatNumber }}</span>
            <p>₹{{ (listing.asking/listing.feature?.size).toFixed(0) }}/{{listing?.feature?.sizeUnit}}</p>
         </div> -->
         <div class="price">
            <span class="unit-value">{{ listing?.asking | formatNumber }}</span>
            <p class="unit-per-unit">₹{{ (listing.asking / listing.feature?.size).toFixed(0) }}/{{ listing?.feature?.sizeUnit }}</p>
          </div>
          
         <div class="area">
            <span  class="unit-value">{{ listing?.feature?.size }} {{ listing?.feature?.sizeUnit }}</span>
            <p class="unit-per-unit">Plot Area</p>
         </div>
         <div class="bhk-status">
            <span class="unit-value">{{ listing.noOfBadroom }} BHK ({{ listing.noOfBathroom }}
               Baths)</span>
            <p >Ready to Move</p>
         </div>
      </div>
      <div class="listing-description" matTooltip="{{listing.description | stripHtml }}">
         <span class="description-text" [innerHTML]="listing.description | safeHtml"></span>
       </div>

       <div class="listing-footer">
         <div class="footer-info">
             <span>{{ listing.createdAt | timeAgo }}</span><br>
             <span class="owner">Owner</span>
         </div>
         <div class="footer-info">
             <span>{{ listing.type }}</span><br>
         </div>
     
         <div *ngIf="editButton" class="footer-btn">
             <button class="btn btn-primary" (click)="editDetail(listing.id)">
                 <i class="fa fa-edit" aria-hidden="true"></i>&nbsp; Edit Details
             </button>
         </div>
         <div class="footer-btn">
             <button class="btn btn-primary" (click)="navigateToDetail(listing.id)">
                 <i class="fa fa-star" aria-hidden="true"></i>&nbsp; View Details
             </button>
         </div>
         <div class="footer-btn">
             <button class="btn btn-primary" (click)="openShareModal(listing)">
                 <i class="fa fa-share-alt" aria-hidden="true"></i>&nbsp; Share
             </button>
         </div>
     </div>
     
     

   </div>
</div>