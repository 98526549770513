<div class="row mt-5">
    <div class="col-md-9 mx-auto">
        <h2 class="text-left">Google Map</h2>
        <div class="card mt-3">
            <div class="card-body">
                <div #mapRef style="width:600px;height:300px"></div>
            </div>
        </div>
        <!--div class="card mt-3">
            <div class="card-body">
                <div id="map" leaflet [leafletOptions]="mapOptions">
                </div>
            </div>
        </div-->
    </div>
</div>