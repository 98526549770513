import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CellClickedEvent, GridApi, GridOptions, ICellRendererParams } from 'ag-grid-community';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/_services/home.service';
import { LoadingService } from 'src/app/_services/loading.service';
import { TokenStorageService } from 'src/app/_services/token-storage.service';

@Component({
  selector: 'app-trending-project-listing',
  templateUrl: './trending-project-listing.component.html',
  styleUrls: ['./trending-project-listing.component.scss']
})
export class TrendingProjectListingComponent implements OnInit {
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any = [];
  defaultColDef: any = {};
  rowData: any = [];
  IsLoading: boolean = false;
  paginationPageSize!: number;
  subs$!: Subscription;
  gridOptions: GridOptions = {
    pagination: true,
    rowHeight: 40,
  };
  userId:any;
  module: any;
  subModule: any;
  accessRight: any;
  createRight: boolean = false;
  deleteRight: boolean = false;
  updateRight: boolean = false;
  viewRight: boolean = false;
  userType: any;
  public frameworkComponents:any;
  user: any;
  selectedOption: any = null;
  userlist: any[] = [];
  trendingList: any[] = [];
  searchValue: string = ''; 
  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getProjectList()
  }
  onSearchTermChange(searchTerm: string) {
    this.searchValue = searchTerm;
    this.getProjectList(); 
  }
  getProjectList() {
    this.homeService
      .getProjectList('trending',this.searchValue || '')
      .subscribe((response: any) => {
        this.IsLoading = false;
        if (response.status === "SUCCESS") {
          this.trendingList = response.payload.items; 
          console.log(this.rowData);
        } else {
          console.error('Failed to fetch projects');
        }
      }, error => {
        this.IsLoading = false;
        console.error('Error fetching projects:', error);
      });

  }
  stripHtmlTags(input: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    return doc.body.textContent || '';
  }
}