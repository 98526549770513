<div class="row col-12 d-flex justify-content-between align-items-center firstcontain">
    <div class="col-11 text-start">
      <div style="font-weight: bold; font-size: 18px;">Contact Seller</div>
    </div>
    <div class="col-1 text-end">
      <button class="close-button" (click)="closeDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="row col-12 d-flex justify-content-between align-items-center">
    <div class="col-3 text-start">
   <img>
    </div>
    <div class="col-9 text-start">
   
    </div>
  </div>
  