import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/_services/home.service';
import { LoadingService } from 'src/app/_services/loading.service';

@Component({
  selector: 'app-top-project-listing',
  templateUrl: './top-project-listing.component.html',
  styleUrls: ['./top-project-listing.component.scss']
})
export class TopProjectListingComponent implements OnInit {
  searchValue: string = '';  // Initialize searchValue as an empty string
  topList: any[] = [];
  IsLoading: boolean = false;

  constructor(
    private homeService: HomeService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getProjectList();
  }

  onSearchTermChange(searchTerm: string) {
    this.searchValue = searchTerm;
    this.getProjectList(); 
  }

  getProjectList(): void {
    this.IsLoading = true;

    // Ensure that the searchValue is passed as an empty string if it's undefined
    this.homeService.getProjectList('top', this.searchValue || '').subscribe((response: any) => {
      this.IsLoading = false;
      if (response.status === 'SUCCESS') {
        this.topList = response.payload.items;
      } else {
        console.error('Failed to fetch projects');
      }
    }, error => {
      this.IsLoading = false;
      console.error('Error fetching projects:', error);
    });
  }
}
