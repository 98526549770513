<div class="page-container">
    <app-header></app-header>
    <app-menu></app-menu><br>
    <div class="content p-3" style="padding: 50px;">
        
        <form [formGroup]="myForm" (ngSubmit)="onSubmit()" class="custom-form">
            <h2 style="text-align: center;">Contact Us</h2>
          
            <div class="form-group">
              <label for="email" class="form-label">Email</label>
              <input type="email" class="form-control" placeholder="Email" formControlName="email" />
            </div>
          
            <div class="form-group">
              <label for="phone" class="form-label">Phone</label>
              <input type="tel" class="form-control" placeholder="Phone" formControlName="phone" />
            </div>
          
            <div class="form-group">
              <label for="description" class="form-label">Query</label>
              <textarea class="form-control" placeholder="Enter your query" formControlName="description" rows="4"></textarea>
            </div>
          
            <button type="submit" class="submit-btn" style="color: white;">Submit</button>
          </form>
          
    </div>
    <app-footer></app-footer>
</div>
