import { Component, OnInit, ViewChild, ElementRef ,  TemplateRef } from '@angular/core';
import * as Leaflet from 'leaflet';
import { antPath } from 'leaflet-ant-path';
import { MapService } from '../_services/map.service';
import { ActivatedRoute,Event, NavigationEnd, Router } from '@angular/router';
import {environmentbasic} from '../../environments/environment-basic';
import { Country, State, City } from 'country-state-city';
import {latLng, MapOptions, tileLayer,Map, Marker, icon} from 'leaflet';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  mapOptions: MapOptions;
  //map: Map;
  propertyList =[];
  public loading = true;
  map: Leaflet.Map;
  expertProfiles: any = [];
  rejectOfferTemplate:TemplateRef<any>;
  profilePic: string = '../assets/images/profile1.png';
  markerIcon: string = '../assets/images/map-marker.png';
  url:string = environmentbasic.basicUrl;
  selectedCountry: any = '';
  countries: any = [];
  states:any =[];
  bounds:any =[];
  geojson:any ='';
  marker:any =[];
  dropdownSettings: IDropdownSettings = {};
  countryImage:string = '';
  latitude:any = '';
  longitude:any ='';
  countryCode:any ='';
  


  artParama: any = { "limit":10, "offset": 0, "searchString": "" };
  @ViewChild('more') more: ElementRef;
  constructor(
    private mapService: MapService,
    private router: Router
  ) { }

  ngOnInit(): void {

    this.loading = true;
    this.countries = Country.getAllCountries();
    this.dropdownSettings = {
			singleSelection: true,
			idField: 'isoCode',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 10,
			limitSelection: 1,
			enableCheckAll: true,
			allowSearchFilter: true
		};
    
    this.loadMap();
    this.user();
      

    }
    loadMap(){
      this.map = Leaflet.map('map', {
        center: [51.759445, 19.457216],
        zoom: 3
      });
      //this.map1 = new Leaflet.Map('map2').setView([42.35663, -71.1109], 3); 
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data © OpenStreetMap contributors',maxZoom: 18,minZoom:3,noWrap: true
      }).addTo(this.map);
    }
  
     
    makeCapitalPopup(data: any): string {
      return `<div style="font-size: 18px; color:#007bff;">${ data.firstName } ${ data.lastName }</div>` +
        `<img src=${ data.profilePic!= null ? data.profilePic : this.profilePic } style="width:120px; height:120px;" width=120px,height=100px/>`+
        `<div style="margin-top:6px;"><a class="btn-sm btn-danger" style="color:white;"
           href="${this.url}#/profile/${data.username}/${data.id}/portfolio">More</a></div>`;
    }
  
    leafletMap(): void {
      const markerIcon = Leaflet.icon({
      iconSize: [20, 35],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      // specify the path here
      iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
      //shadowUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-shadow.png"
      });

      for (let expert of this.expertProfiles) {
      Leaflet.marker([expert.latitude,expert.longitude],{icon: markerIcon }).addTo(this.map)
      .bindPopup(this.makeCapitalPopup(expert));   
      this.latitude= expert.latitude;
    this.longitude = expert.longitude;        
      }
    
  
  
}

user(){
  if(this.countryCode == '' || this.countryCode == null ){

    this.mapService.fetchUser1().subscribe(response => {
          
      if (response['status'] == 'SUCCESS') {
        if (response['payload']['userProjects'] && response['payload']['userProjects'].length > 0) {
          this.expertProfiles = response['payload']['userProjects'];
          this.leafletMap();
          //this.addSampleMarker();
          this.loading = false;
                        
        }
      }
  
    });
    

  }
  else if (this.countryCode !='' ){
    this.map.remove();
      this.map = new Leaflet.Map('map').setView([this.latitude, this.longitude], 3);  
      Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: 'Map data © OpenStreetMap contributors',minZoom:3, maxZoom:18,noWrap: true
      }).addTo(this.map);
    
    this.mapService.fetchUser(this.countryCode).subscribe(response => {
          
      if (response['status'] == 'SUCCESS') {
        if (response['payload']['userProjects']) {
          this.expertProfiles = response['payload']['userProjects'];
          this.leafletMap();
          //this.addSampleMarker();
          this.loading = false;
                        
        }
      }
  
    });
    

  }
  

}
changeCountry(e) {
  this.countryCode = this.selectedCountry[0].isoCode;
  this.user();
}
removeCountry(e){
  this.countryCode = this.selectedCountry.splice(1,1);
  this.user();

}

}

 
    
  


  


