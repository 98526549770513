import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HomeService } from '../_services/home.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.scss']
})
export class AddProjectComponent implements OnInit {
  @ViewChild(MatStepper) stepper: MatStepper;
  selectedFiles: File[] = [];
  selectedFile: File[] = [];
  projectId: number | null = null;

  selectedUnit: number = 1;
  selectedWidth: string = '';
  selectedSize: string = '';

  calculatedWidth: string = '';
  calculatedSize: string = '';
  inputAsking: number = 0;
  inputWidth: string ='';
  inputSize: string = '';
  selectedFileNames: string[] = [];
  selectedFileName: string[] = [];

  isLinear = false;
  countryId = 259;
  stateList: any[] = [];
  cityDataList: any[] = [];
  id: any;
  stateId: number;
  stateName: string;
  data: any;
  cityid: number;
  payload: any;
  paramsData: any;
  projectid: string;
  statusValue:any;
  pdfurl: any;
  media: any = [];
  length: number = 1;
  image: any = [];
  Urls: string[] = [];
  imageItems: any[] = [
    {
      constrolName: 'bannerImage',
      purpose: 'bannerImage',
      index: 0,
      url: '',
      type: 'image',
      controlArray: true,
      loading: false,
      linkadd: false,
    },

  ];

  imageItem: any[] = [
    {
      constrolName: 'imageUrls',
      purpose: 'propertiesImage',
      index: 0,
      url: '',
      type: 'image',
      controlArray: true,
      loading: false,
      linkadd: false,
    },

  ];

  uploadedImages: any[] = [];
  uploadedbanner: any[] = [];
  projectFeature:any
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  constructor(private _formBuilder: FormBuilder,
    private homeService: HomeService,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) { }
  get description() {
    return this.firstFormGroup.get('description');
  }
  ngOnInit(): void {
    this.getCitybyCountryId()
    this.route.queryParams.subscribe((params) => {
      this.projectId = +params['id']; // Make sure you use `this.projectId` here
      if (this.projectId) { // Corrected variable name
        this.projectById(); 
      } else {
        console.error('No projectid found in query params');
      }
    });
    

   
      this.firstFormGroup = this._formBuilder.group({
        cityId: ['', Validators.required],
        description: ['', Validators.required],
        fromPrice: ['', Validators.required],
        inventoryType: ['', Validators.required],
        loanAvailable: ['', Validators.required],
        location: ['', Validators.required],
        projectName: ['', Validators.required],
        reraApproved: ['', Validators.required],
        stateId: ['', Validators.required],
        status: [null, Validators.required],
        toPrice: ['', Validators.required],
        title:['', Validators.required],
        uploadbanner: [''],
        uploadPdf:[]
      });
  
      this.secondFormGroup = this._formBuilder.group({
        builder: ['', Validators.required],
        connectivity: ['', Validators.required],
        constructionApprovals: ['', Validators.required],
        flatPurchaseChecklist: ['', Validators.required],
        investmentPerspective: ['', Validators.required],
        security: ['', Validators.required],
        accessTo: ['', Validators.required],
        zoningRisks: ['', Validators.required],
        verification: ['', Validators.required],
        modernFeatures: ['', Validators.required],
        noObjectionCertificates: ['', Validators.required],
        marketResearch: ['', Validators.required],
      });
      // this.route.paramMap.subscribe(params => {
      //   this.projectId = +params.get('id')!;
      //   console.log("this.projectId",this.projectId)
      // });
    }
  
  onStepChange(event: any) {
    // Check which step is being selected
    const stepIndex = event.selectedIndex;
    // Validation for the first form group
    if (stepIndex === 0 && this.firstFormGroup.invalid) {
      event.preventDefault();
      this.firstFormGroup.markAllAsTouched();
      return;
    }
    // Validation for the second form group
    if (stepIndex === 1 && this.secondFormGroup.invalid) {
      event.preventDefault();
      this.secondFormGroup.markAllAsTouched();
      return;
    }
    // Validation for the third form group
  
  }

  handleCancel(){
    const id = this.route.snapshot.queryParamMap.get('id')
    if(id){
      this.router.navigate(['/UserProperty'])
    }
    else{
      this.router.navigate(['/home-property'])
    }
  }
  getCitybyCountryId() {
    this.homeService.getCitybyCountryId().subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.cityDataList = response.payload.cityDataList;
        } else {
          console.error('Failed to fetch city data:', response);
        }
      },
      (error) => {
        console.error('Error fetching city data:', error);
      }
    );
  }
  onCityChange(event: any) {
    const selectedCityId = event.value;
    this.getStateByCityId(selectedCityId);
  }

  getStateByCityId(cityId: number) {
    this.homeService.getStateByCityId(cityId).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.stateId = response.payload.stateId;
          this.stateName = response.payload.stateName;
          this.cd.detectChanges();
          this.firstFormGroup.patchValue({
            stateId: this.stateId,
          });
          this.cd.detectChanges();
        } else {
          console.error('Failed to fetch state data:', response);
        }
      },
      (error) => {
        console.error('Error fetching state data:', error);
      }
    );
  }
  onSubmit() {
    const projectid = this.route.snapshot.queryParamMap.get('id')
    const controls = this.firstFormGroup.controls;
    for (const i in controls) {
      if (controls.hasOwnProperty(i)) {
        controls[i].markAsDirty();
        controls[i].updateValueAndValidity();
      }
    }
    if (this.firstFormGroup.valid) {
      let payload = { ...this.firstFormGroup.value };
      const fieldsToRemove = ['uploadPdf', 'uploadbanner',]; 
      fieldsToRemove.forEach(field => {
        delete payload[field];
      });
      payload = {
        ...payload,
        bannerImage: this.uploadedbanner,
        imageUrls: this.uploadedImages
      };
      if (projectid) {
        this.UpdateBasicDetail(payload, projectid);
      } else {
        this.saveProject(payload);
      }
      this.stepper.next();
    } else {
      console.log('Form is invalid');
    }
  }
  saveProject(payload: any) {
    this.homeService.saveProject(payload).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.id = response.payload.id;
          this.paramsData = response.payload;
          if (this.id) {
            this.router.navigate(['/add-project'], {
              queryParams: { id: this.id },
            });
          } else {
            console.error('No id returned in response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
  UpdateBasicDetail(payload: any, id: any) {
    if (this.firstFormGroup.valid) {
      let payload = { ...this.firstFormGroup.value };
      payload = {
        ...payload,
        bannerImage: this.uploadedbanner,
        imageUrls: this.uploadedImages
      };
    }
    this.homeService.UpdateProject(payload, id).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.id = response.payload.id;
          this.paramsData = response.payload;
          if (this.id) {
            this.router.navigate(['/add-project'], {
              queryParams: { id: this.id },
            });
          } else {
            console.error('No id returned in response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
  updateprojectfeatures() {
    const projectid = this.route.snapshot.queryParamMap.get('id')
    if (!this.secondFormGroup.valid) {
      console.error('Form is invalid');
      return;
    }
    const firstFormGroupValues = { ...this.firstFormGroup.value };
    delete firstFormGroupValues.uploadPdf;
    delete firstFormGroupValues.uploadbanner;
    const projectFeatures = {
      projectFeatures: this.secondFormGroup.value, 
    };
    const payload = {
      ...firstFormGroupValues,  
      ...projectFeatures, 
      bannerImage: this.uploadedbanner,
      imageUrls: this.uploadedImages
    };
    this.homeService.UpdateProject(payload, projectid).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.router.navigate(['/myprojects']);
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
  projectById() {
    this.homeService.getProjectById(this.projectId
    ).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          this.data = response?.payload;
          let selectedCityId=response?.payload?.cityId;
          this.getStateByCityId(selectedCityId);
          this.stateId = response?.payload?.stateId;
          this.projectFeature=response?.payload?.projectfeatures
          this.statusValue = response?.payload?.status;
          this.uploadedImages  = response?.payload?.projectPlanImage.map(media => media.url);
          this.uploadedbanner  = response?.payload?.bannerImage.map(media => media.url);
          this.firstFormGroup.patchValue({
            title: this.data.title,
            description: this.data.description,
            status: this.statusValue, 
            cityId: this.data.cityId,
            stateId: this.stateId,
            reraApproved:this.data.reraApproved,
            inventoryType:this.data.inventoryType,
            loanAvailable:this.data.loanAvailable,
            projectName:this.data.projectName,
            toPrice:this.data.toPrice,
            fromPrice:this.data.fromPrice,
            location:this.data.location
          });
          this.cd.detectChanges();
          this.secondFormGroup.patchValue({
            builder: this.projectFeature.builder,
            connectivity: this.projectFeature.connectivity,
            constructionApprovals: this.projectFeature.constructionApprovals,
            flatPurchaseChecklist: this.projectFeature.flatPurchaseChecklist,
            investmentPerspective: this.projectFeature.investmentPerspective,
            security: this.projectFeature.security,
            accessTo: this.projectFeature.accessTo,
            zoningRisks: this.projectFeature.zoningRisks,
            verification: this.projectFeature.verification,
            modernFeatures: this.projectFeature.modernFeatures,
            noObjectionCertificates: this.projectFeature.noObjectionCertificates,
            marketResearch: this.projectFeature.marketResearch,
          })
          this.cd.detectChanges();
          

        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
  triggerFileInput(): void {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.click();
  }
  triggerFileInput1(): void {
    const fileInput = document.getElementById('fileInput1') as HTMLInputElement;
    fileInput.click();
  }
  uploadFiles(): void {
    if (this.selectedFile.length) {
      for (let i = 0; i < this.selectedFile.length; i++) {
        const selectedFile = this.selectedFile[i];
        const type = this.imageItem[i];
        const mimeType = selectedFile.type;
        const purpose = type.purpose;

        this.homeService.getMediaData(purpose, mimeType, selectedFile)
          .pipe(finalize(() => { this.imageItem[i].loading = false; }))
          .subscribe((res: any) => {
              this.uploadedbanner.push(res.url);
            this.cd.detectChanges();
          });
      }

      this.clearFileInput1();
    }
  }

  handleChanges(event: any): void {
    const files = event.target.files;
    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        this.selectedFile.push(files[i]);
        this.selectedFileName.push(files[i].name);
        this.imageItem.push({ loading: true, purpose: 'bannerImage' });
      }
      this.cd.detectChanges();
    }
  }
  
  handleChange(event: any): void {
    const file = event.target.files;
    if (file && file.length) {
      for (let i = 0; i < file.length; i++) {
        this.selectedFiles.push(file[i]);
        this.selectedFileNames.push(file[i].name);
        this.imageItems.push({ loading: true, purpose: 'propertiesImage' });
      }
      this.cd.detectChanges();
    }
  }
  

  uploadFile(): void {
    if (this.selectedFiles.length) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        const selectedFile = this.selectedFiles[i];
        const type = this.imageItems[i];
        const mimeType = selectedFile.type;
        const purpose = type.purpose;
        this.homeService.getMediaData(purpose, mimeType, selectedFile)
          .pipe(finalize(() => { this.imageItems[i].loading = false; }))
          .subscribe((res: any) => {
           
              this.uploadedImages.push(res.url);
            this.cd.detectChanges();
          });
      }

      // Clear the file input and reset selected files and names
      this.clearFileInput();
    }
  }

  clearFileInput(): void {
    this.selectedFiles = [];
    this.selectedFileNames = [];
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    fileInput.value = ''; 
    this.cd.detectChanges();
  }
  clearFileInput1(){
    this.selectedFile = [];
    this.selectedFileName = [];
    const fileInput = document.getElementById('fileInput1') as HTMLInputElement;
    fileInput.value = ''; 
    this.cd.detectChanges();
  }
  removeImage(index: number): void {
    this.uploadedImages.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.imageItems.splice(index, 1);
    this.cd.detectChanges();
  }
  removeBanner(index:number):void{
    this.uploadedbanner.splice(index, 1);
    this.selectedFiles.splice(index, 1);
    this.selectedFileNames.splice(index, 1);
    this.imageItems.splice(index, 1);
    this.cd.detectChanges();
  }

}
