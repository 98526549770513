<div class="container row col-12">
  <img src="https://housing-images.n7net.in/3a1452c5/dd928f9d695b0151169ed67f202c8117/v0/banner.jpg" class="image">
  <div class="image-overlay"></div>
  <div class="header header-one">
    <div class="row p-3">
      <div class="container-fluid">
        <div class="row d-flex justify-content-between align-items-center">
          <!-- Logo Section -->
          <div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start">
            <img src="../../assets/images/logo45.png" alt="Logo" class="firstlogo">
          </div>

          <!-- Navigation and Menu Section -->
          <div class="col-12 col-md-6 d-flex flex-wrap justify-content-end align-items-center">
            <div class="nav-item tiktok d-flex align-items-center justify-content-end" *ngIf="userinfo else notlogin">
              <!-- Links and Button -->
              <a (click)="newsnavigate()" class="post-property-link mx-2.4">News</a>
              <a routerLink="/add-property" class="post-property-link mx-2.4">Add Property</a>
              <a routerLink="/contactUs" class="post-property-link mx-2.4">Any Query?</a>
              <a *ngIf="builder" routerLink="/add-project" class="post-property-link mx-2.4">Add Project</a>
              <button mat-icon-button [matMenuTriggerFor]="menu" class="title mx-2.4">
                <i class="fa fa-user-circle logbutton"></i>
              </button>

              <!-- Menu Items -->
              <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="/update/profile/username/userid/activetab">
                  <i class="fas fa-user" style="color: #004aad; font-size: 16px;"></i>
                  <span>&nbsp; My Profile</span>
                </button>
                <button mat-menu-item (click)="navigateToUserProprty()">
                  <i class="fa fa-building" style="color: #004aad; font-size: 16px;"></i>
                  &nbsp; My Property
                </button>
                <button *ngIf="builder" mat-menu-item (click)="navigateToUserProject()">
                  <i class="fa fa-home" style="color: #004aad; font-size: 16px;"></i>
                  &nbsp; My Project
                </button>
                <button mat-menu-item (click)="userLogout()">
                  <i class="fas fa-sign-out-alt" style="color: #004aad; font-size: 16px;"></i>
                  &nbsp; Logout
                </button>
              </mat-menu>
            </div>

            <!-- Not Logged In Template -->
            <ng-template #notlogin>
              <div class="d-flex align-items-center">
                <a (click)="newsnavigate()" class="post-property-link mx-2" style="font-size: 14px;">News</a>
                <a routerLink="/login" class="post-property-link mx-2" style="font-size: 14px; color: white;">Login |
                  Signup</a>
              </div>
            </ng-template>
          </div>

        </div>
      </div>

    </div>
  </div>


  <div class="header header-two" style="background-color: aliceblue;">

    <mat-tab-group class="custom-tabs" (selectedTabChange)="onTabChange($event)">
      <mat-tab *ngFor="let tab of tabs" [label]="tab.name">
      </mat-tab>
    </mat-tab-group>


    <div class="custom-search">

      <div class="row align-items-center">


        <div class="col-md-2 d-flex justify-content-start">
          <div class="styled-select-container">
            <select class="styled-select" [(ngModel)]="selectedCityId" (change)="onCityChange()">
              <option *ngFor="let city of cityDataList" [value]="city.cityId">
                {{ city.cityName }}
              </option>
            </select>
          </div>
        </div>

       <div class="col-md-8 d-flex justify-content-start position-relative">
          <input type="text" #searchkey class="form-control search-input" 
            placeholder="Search for locality, landmark, project, or builder"
            [(ngModel)]="searchQuery" 
            (keyup)="onSearchKeyChange()" 
            (focus)="showSuggestions = true"
            (blur)="hideSuggestions()" />
        
          <div class="suggestion-container">
            <ul *ngIf="showSuggestions && areaList.length > 0" class="suggestion-dropdown">
              <li *ngFor="let area of areaList" (click)="selectArea(area)">
                {{ area.area | titlecase }}, {{ area.cityName | titlecase }}
              </li>
            </ul>
          </div>
        </div>


        <div class="col-md-2 d-flex justify-content-end">
          <button class="btn btn-primary11" #searchkey (click)="searchArt()">Search</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-md-12 " style="margin-top: 10pc; z-index: 0;">
  <div class="col-md-12 ">
    <span class="topproject">Top Projects</span><br><br>
    <span class="toppro">Best developers in Gurgaon to explore</span>
  </div>
  <div class="swiper-container" style="display: flex; width: auto !important;">
    <swiper #swiperRef (slideChange)="onSlideChange($event)" [slidesPerView]="1" [spaceBetween]="15" [navigation]="{ nextEl: '.custom-next-button1',
      prevEl: '.custom-prev-button1' }" [breakpoints]="{
          '768': { 'slidesPerView': 3 }
        }" style="width: 80%;">
      <ng-template swiperSlide *ngFor="let card of topList">
        <!-- <app-top-property-card (click)="navigateTofullDetail()"
    [cardData]="card"
    [showEditDetailsButton]="false"
    [showViewDetailsButton]="false"
    [showInventoryButton]="false"
    [showEditInventoryButton]="false">
  </app-top-property-card>  -->
        <app-top-property-card (click)="navigateTofullDetail(card.id)" [cardData]="card" [showEditDetailsButton]="false"
          [showViewDetailsButton]="false" [showInventoryButton]="false" [showEditInventoryButton]="false">
        </app-top-property-card>

      </ng-template>
    </swiper>

    <div class="custom-prev-button1" (click)="onPrevClick(0)" [ngClass]="{'hide': !showPrevButton}">
      <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
        arrow_back
      </mat-icon>
    </div>
    <div class="custom-next-button1" (click)="onNextClick(0)" [ngClass]="{'hide': !showNextButton}">
      <mat-icon [ngClass]="{'active': activeButtons[0], 'inactive': !activeButtons[0]}">
        arrow_forward
      </mat-icon>
    </div>

  </div>

</div>

<div class="col-md-12" style="margin-top: 3pc;">
  <div class="col-md-12">
    <span class="topproject">Recently Added</span><br><br>
    <span class="toppro">Based on preferences of users like you</span>
  </div>

  <div class="swiper-container" style="display: flex; width: auto !important;">
    <swiper #swiperRef2 [slidesPerView]="1" [spaceBetween]="10"
      [navigation]="{ nextEl: '.custom-next-button2', prevEl: '.custom-prev-button2' }"
      (slideChange)="onSlideChange2($event)" [breakpoints]="{
              '768': { 'slidesPerView': 3 }
            }" style="width: 80%;">
      <ng-template swiperSlide *ngFor="let card of recentList">
        <app-top-property-card-small (click)="navigateTofullDetail(card.id)"
          [cardData]="card"></app-top-property-card-small>
      </ng-template>
    </swiper>

    <div class="custom-prev-button2" (click)="onPrevClick(1)" [ngClass]="{'hide': !showPrevButton2}">
      <mat-icon [ngClass]="{'active': activeButtons[1], 'inactive': !activeButtons[1]}">
        arrow_back
      </mat-icon>
    </div>
    <div class="custom-next-button2" (click)="onNextClick(1)" [ngClass]="{'hide': !showNextButton2}">
      <mat-icon [ngClass]="{'active': activeButtons[1], 'inactive': !activeButtons[1]}">
        arrow_forward
      </mat-icon>
    </div>
  </div>
</div>

<div class="col-md-12" style="margin-top: 3pc;">
  <div class="col-md-12">
    <span class="topproject">Trending Projects</span><br><br>
    <span class="toppro">Most sought-after projects in Gurgaon</span>
  </div>

  <div class="swiper-container" style="display: flex; width: auto !important;">
    <swiper #swiperRef3 [slidesPerView]="1" [spaceBetween]="10"
      [navigation]="{ nextEl: '.custom-next-button3', prevEl: '.custom-prev-button3' }"
      (slideChange)="onSlideChange3($event)" [breakpoints]="{
              '768': { 'slidesPerView': 3 }
            }" style="width: 80%;">
      <ng-template swiperSlide *ngFor="let card of trendingList">
        <app-top-property-card-semi-small (click)="navigateTofullDetail(card.id)"
          [cardData]="card"></app-top-property-card-semi-small>
      </ng-template>
    </swiper>

    <div class="custom-prev-button3" (click)="onPrevClick(2)" [ngClass]="{'hide': !showPrevButton3}">
      <mat-icon [ngClass]="{'active': activeButtons[2], 'inactive': !activeButtons[2]}">
        arrow_back
      </mat-icon>
    </div>
    <div class="custom-next-button3" (click)="onNextClick(2)" [ngClass]="{'hide': !showNextButton3}">
      <mat-icon [ngClass]="{'active': activeButtons[2], 'inactive': !activeButtons[2]}">
        arrow_forward
      </mat-icon>
    </div>
  </div>
</div>

<!-- share-post.component.html -->
<!-- <share-buttons
  [theme]="'circles-dark'"
  [include]="['facebook','twitter','linkedin','whatsapp','pinterest','reddit','mix','vk','telegram','messenger','xing','line','sms','email','copy']"
  [show]="9"
  [size]="1"
  [showText]="true"
  [url]="url"
  [title]="title"
  [description]="fullDescription"
  [image]="image"
  [autoSetMeta]="false">
</share-buttons> -->

<br>
<app-footer></app-footer>