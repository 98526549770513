

<div class="page-container">

  <app-header></app-header>
  <app-menu></app-menu><br>
  <div class="container-fluid d-flex flex-column align-items-center " style="margin-top: 2pc;">
    <!-- Avatar/Profile Picture -->
    <div class="avatar-banner">
      <img alt="Profile Picture" src="{{profilePic}}" class="profile-pic" />
    </div>
  
    <!-- Form -->
    <div class="contact-form-wrapper mt-5">
      <div class="contact-form">
        <h3>Your Contact Details</h3>
        <form [formGroup]="updateform" (ngSubmit)="onSubmit()">
          <h2 class="createaccount">Update Your Profile</h2>
  
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Full Name" formControlName="fullName" />
            <div class="errorClass" *ngIf="submitted && f.fullName.errors">
              <div *ngIf="f.fullName.errors.required">Full Name is required</div>
              <div *ngIf="f.fullName.errors.minlength">Full Name must be at least 8 characters</div>
              <div *ngIf="f.fullName.errors.pattern">Full Name must contain alphanumeric characters</div>
            </div>
          </div>
  
          <div class="form-group">
            <input type="email" class="form-control" placeholder="Email" formControlName="email" />
            <div class="errorClass" *ngIf="submitted && f.email.errors">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
  
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Mobile Number" formControlName="mobileNumber" />
            <div class="errorClass" *ngIf="submitted && f.mobileNumber.errors">
              <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
              <div *ngIf="f.mobileNumber.errors.pattern">Mobile Number must be valid</div>
            </div>
          </div>
  
          <div class="form-group">
            <input type="password" class="form-control" placeholder="Password" formControlName="password" />
            <div class="errorClass" *ngIf="submitted && f.password.errors">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.pattern">Password must be valid</div>
            </div>
          </div>
  
          <div style="width: 100%; padding: 0 15px;">
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div style="flex: 0 0 75%; max-width: 75%;">
                <div style="display: flex; gap: 20px;">
                  <label>
                    <input type="radio" formControlName="userType" [value]="'individual'" /> Individual
                  </label>
                  <label>
                    <input type="radio" formControlName="userType" [value]="'builder'" /> Builder
                  </label>
                  <label>
                    <input type="radio" formControlName="userType" [value]="'agent'" /> Agent
                  </label>
                </div>
              </div>
          
              <div style="flex: 0 0 25%; max-width: 25%; display: flex; justify-content: flex-end; align-items: center;">
                <div style="cursor: pointer; color: #007bff; font-size: 16px;">
                  <span (click)="navigateToReset()"> Reset Password</span>
                </div>
              </div>
            </div>
          </div>
          
          
          
  
          <div class="button-group">
            <button class="btn" type="submit">Update & Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <app-footer style="padding-top: 20px;" ></app-footer>
</div>