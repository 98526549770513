import { Component, OnInit } from '@angular/core';
import { ContactSellerComponent } from '../contact-seller/contact-seller.component';
import { MatDialog } from '@angular/material/dialog';
import { TopProjectService } from '../_services/top-project.service';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from '../_services/home.service'; 

@Component({
  selector: 'app-top-project',
  templateUrl: './top-project.component.html',
  styleUrls: ['./top-project.component.scss']
})
export class TopProjectComponent implements OnInit {
  projectId: string | null = null;
  listing: any;
  cardData: any;
  toplist: any;
  title: string = '';
  showAll = false; 
  fromPrice: number | null = null;
  toPrice: number | null = null;
  location: any = '';
  cityName: any = '';
  stateName: any = '';
  isExpanded: boolean = true;
  Images: string[] = []; 
  inventoryType: any = '';
  sizeOfProperty: any = '';
  titleDeed: any = '';
  area: any = '';
  soilQuality: any = '';
  physicalVisit: any = '';
  propertyLocation: any = '';
  size: any = '';
  pricing: any = '';
  projectName: any = '';
  reraApproved: any = '';
  reraCompliance: any = '';
  builder: any = '';
  outdoor: any = '';
  smartHomes: any = '';
  infrastructure: any = '';
  highEndKitchens: any = '';
  floorPlan: any = '';
  description: string = "<p>This is a <strong>sample</strong> description.</p>";
  apartmentPossession: any = '';

  constructor(
    private dialog: MatDialog,
    private homeService: HomeService,
    private route: ActivatedRoute,
    private topProjectService: TopProjectService
  ) { }

  ngOnInit(): void {
    this.projectId = this.route.snapshot.queryParamMap.get('id');
    if (this.projectId) {
      this.homeService.getProjectById(this.projectId).subscribe(
        (response: any) => {
          if (response.status === 'SUCCESS' && response.payload) {
            this.title = response.payload.title || ''; 
            this.fromPrice = response.payload.fromPrice || null;
            this.toPrice = response.payload.toPrice || null;
            this.location = response.payload.location || '';
            this.cityName = response.payload.cityName || '';
            this.stateName = response.payload.stateName || '';
            this.titleDeed = response.payload?.projectfeatures?.titleDeed || '';
            this.inventoryType = response.payload?.inventoryType || '';
            this.sizeOfProperty = response.payload?.projectBuilder?.sizeOfProperty || '';
            this.area = response.payload?.projectBuilder?.area || '';
            this.soilQuality = response.payload?.projectfeatures?.soilQuality || '';
            this.physicalVisit = response.payload?.projectfeatures?.physicalVisit || '';
            this.propertyLocation = response.payload?.projectfeatures?.propertyLocation || '';
            this.pricing = response.payload?.projectfeatures?.pricing || '';
            this.size = response.payload?.projectfeatures?.size || '';
            this.projectName = response.payload?.projectName || '';
            this.reraCompliance = response.payload?.projectfeatures?.reraCompliance || '';
            this.reraApproved = response.payload?.reraApproved || '';
            this.outdoor = response.payload?.projectfeatures?.outdoor || '';
            this.smartHomes = response.payload?.projectfeatures?.smartHomes || '';
            this.infrastructure = response.payload?.projectfeatures?.infrastructure || '';
            this.highEndKitchens = response.payload?.projectfeatures?.highEndKitchens || '';
            this.floorPlan = response.payload?.projectfeatures?.floorPlan || '';
            this.builder = response.payload?.projectfeatures?.builder || '';
            this.description = response.payload?.description || '';
            this.apartmentPossession = response.payload?.projectfeatures?.apartmentPossession || '';

            if (response.payload.projectPlanImage && response.payload.projectPlanImage.length > 0) {
              this.Images = response.payload.projectPlanImage.map((image: any) => image.url || '');
            }

            console.log('Title:', this.titleDeed);
          }
        }
      );
    }

    console.log("Project ID:", this.projectId);
  }

  opencontactceller(): void {   
    this.dialog.open(ContactSellerComponent, {
      width: '540px',
      height: '670px',
    });
  }

  amenities = [
    { icon: 'weekend', label: 'Waiting Lounge' },
    { icon: 'spa', label: 'Steam Room' },
    { icon: 'nature', label: 'Reflexology Park' },
    { icon: 'outdoor_grill', label: 'Barbecue Area' },
    { icon: 'hot_tub', label: 'Spa' },
    { icon: 'local_bar', label: 'Bar/Chill-out Lounge' },
    { icon: 'celebration', label: 'Party Hall' },
    { icon: 'sports_tennis', label: 'Table Tennis' },
    { icon: 'sports_handball', label: 'Badminton Court' },
    { icon: 'fitness_center', label: 'Gymnasium' },
    { icon: 'pool', label: 'Kid\'s Pool' },
  ];

  toggleShowMore() {
    this.showAll = !this.showAll;
  }

  get displayedAmenities() {
    return this.showAll ? this.amenities : this.amenities.slice(0, 7);
  }

  formatPrice(value: number | null): string {
    if (value === null || value === undefined) return ''; 
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + ' Crore';
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + ' Lakh';
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + ' Thousand';
    }
    return value.toString();
  }

  stripHtmlTags(value: string | null): string {
    if (!value) return ''; 
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = value;

    return tempDiv.textContent || tempDiv.innerText || "";
  }
}
