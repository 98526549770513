import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { WorkExperience } from '../core/models/workexperience';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';


@Injectable({
	providedIn: 'root'
})
export class WorkExperienceService {


	token = '';
	usertoken: any = [];
	socialOrigin = '';
	email = '';
	workExperiences$: BehaviorSubject<WorkExperience[]>;
	workExperiences: Array<WorkExperience> = [];
	constructor(private tokenStorage: TokenStorageService,
		private http: HttpClient) {
		this.usertoken = JSON.parse(this.tokenStorage.getToken());
		this.socialOrigin = this.tokenStorage.getSocialOrigin();
		this.email = this.tokenStorage.getSocialUserEmail();
		if(this.usertoken && this.usertoken.access_token){
			this.token = this.usertoken.access_token;
		}
		this.workExperiences$ = new BehaviorSubject([]);
		
	}

	getAllWorkExperience(pid) : Observable<any>{

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/all/work/experience?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${pid}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	saveWorkExperience(owneruid, workexperience) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/work/experience?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${owneruid}`, JSON.stringify(workexperience), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	updateWorkExperience(owneruid, workexperience) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		const apiUrl=`${environment.apiUrl}/user/work/experience?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${owneruid}`;
		return this.http.post(apiUrl, JSON.stringify(workexperience), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	deleteWorkExperience(owneruid, workExpId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.delete(`${environment.apiUrl}/user/remove/work/experience/${workExpId}?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${owneruid}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

}
