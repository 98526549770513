<app-header></app-header>
<app-menu></app-menu><br>
<div class="container-fluid">
  <div class="form-container">
    <div class="form-wrapper">
      <form [formGroup]="resetform" (ngSubmit)="onResetSubmit()" class="account-form">
        <h3 class="form-title">Reset Password</h3>

        <div class="form-group">
          <input 
            type="password" 
            placeholder="Current Password" 
            formControlName="resetpassword" 
            class="form-control"
          />
          <div class="error-message" *ngIf="submitted && l.resetpassword.errors">
            <div *ngIf="l.resetpassword.errors.required">Password is required</div>
            <div *ngIf="l.resetpassword.errors.minlength">Password must be at least 6 characters</div>
          </div>
        </div>

        <div class="form-group">
          <input 
            type="password" 
            placeholder="New Password" 
            formControlName="resetpasswordconfirm" 
            class="form-control"
          />
          <div class="error-message" *ngIf="submitted && l.resetpasswordconfirm.errors">
            <div *ngIf="l.resetpasswordconfirm.errors.required">Password is required</div>
            <div *ngIf="l.resetpasswordconfirm.errors.minlength">Password must be at least 6 characters</div>
          </div>
        </div>

        <button type="submit" class="btn-reset-password">Reset Password</button>
        <a routerLink="/login" class="link-login">Login</a>
      </form>
    </div>
  </div>
</div>
<app-footer></app-footer>
