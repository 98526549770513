<app-header></app-header>
<br>
<div class="card-container" *ngIf="topList.length > 0">
  <ng-container *ngFor="let card of topList">
    <app-top-property-card
    [cardData]="card"
    [showEditDetailsButton]="true"
    [showViewDetailsButton]="true"
    [showInventoryButton]="true"
    [showEditInventoryButton]="false">
  </app-top-property-card>
  </ng-container>
</div>
<div *ngIf="topList.length === 0">
  <p>No projects available.</p>
</div>

<app-footer></app-footer>


