import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from '../_services/home.service';

@Component({
  selector: 'app-myprojects',
  templateUrl: './myprojects.component.html',
  styleUrls: ['./myprojects.component.scss']
})
export class MyprojectsComponent implements OnInit {
  IsLoading: boolean = false;
  topList: any[] = [];

  constructor(private homeService: HomeService, private router: Router) {}

  ngOnInit(): void {
    this.getProject();
  }

  navigateToAddProject() {
    this.router.navigate(['/add-project']);
  }

  getProject() {
    this.IsLoading = true;
    this.homeService.getMyProjects().subscribe(
      (res: any) => {
        this.IsLoading = false;
        if (res.status === 'SUCCESS' && res.payload) {
          this.topList = res.payload; // Populate the topList array
        } else {
          this.topList = []; // Handle the case when no data is available
        }
      },
      () => {
        this.IsLoading = false;
        this.topList = []; // Handle error case
      }
    );
  }
}
