<div class="row" class="first" style="display: flex;">
  <div class="col-10" >
    <span style="align-items: center;"> You are requesting to view advertiser details.</span>
  </div>
  <div class="col-2" >
    <span class="close-container">
      <button class="close-button"  (click)="closeDialog()">
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </span>
  
  </div>
</div>
<div *ngIf="listingData && listingData.user" style="width: 100%; font-size: 18px;">
  <div class="row" style="margin-top: 5px;">
    <div class="col-md-6">
      <h3 style="font-weight: bold;">POSTED BY OWNER:</h3>
      <div class="row col-sm-12" style="display: flex; font-weight: bold;">
        {{ listingData.user.mobileNumber }} | 
        {{ listingData.user.email }}
      </div>
      <p style="margin-top: 8px;">{{ listingData.user.fullName }}</p>
    </div>
    <div class="col-md-6">
      <h3 style="font-weight: bold;">POSTED ON {{formattedDate}}</h3>
      <div class="row col-sm-12" style="font-weight: bold;">
      {{ listingData.asking | formatNumber  }} | {{ listingData.feature.propertyOwnership }}
      </div>
      <div class="row col-sm-12" style="margin-top: 8px;">
        {{ listingData.feature.size }}{{listingData.feature.sizeUnit}} &nbsp;| {{ listingData.noOfBadroom }} Bedrooms/{{listingData.type}}
      </div>
    </div>
  </div>
</div>

<div class="second">
  <div class="row col-12">
    <span style="margin-left: 5px;">Please fill in your details to be shared with this advertiser only.</span>
  </div>
<!-- </div> -->

</div>

<form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
  <div class="row" style="margin-top: 5px;">
    <div class="col-md-6">
      <h3 style="font-weight: bold;">BASIC INFORMATION</h3>
      <div class="row col-sm-12" style="display: flex;">
        <h3>Your reason to buy is</h3>&nbsp;&nbsp;
        <mat-radio-group formControlName="reasonToBuy" aria-label="Select an option">
          <mat-radio-button value="Investment">Investment</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="Self Use">Self Use</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="row col-sm-12" style="display: flex;">
        <h3>Are you a property dealer</h3>&nbsp;&nbsp;
        <mat-radio-group formControlName="propertyDealer" aria-label="Select an option">
          <mat-radio-button value="Yes">Yes</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="No">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <p>By when you are planning to buy the property?</p><br>
      <p style="margin-top: -10px;">
        <mat-radio-group formControlName="planningToBuy" aria-label="Select an option">
          <mat-radio-button value="3 months">3 months</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="6 months">6 months</mat-radio-button>&nbsp;&nbsp;
          <mat-radio-button value="more than 6 months">more than 6 months</mat-radio-button>
        </mat-radio-group>
      </p>
      
      <!-- <div class="component__inputGroup">
        <div>
          <h2>User Profile</h2>
          <p><strong>Full Name:</strong> {{ contactForm.get('name').value }}</p>
          <p><strong>Email:</strong> {{ contactForm.get('email').value }}</p>
          <p><strong>Mobile Number:</strong> {{ contactForm.get('mobile').value }}</p>
        </div>
      </div> -->
      
    </div>
    <div class="col-md-6">
      <h3 style="font-weight: bold;">OPTIONAL INFORMATION</h3>
      <div class="row col-sm-12">
        
        <p>
          <mat-checkbox formControlName="homeLoan">I am interested in home loan</mat-checkbox><br>
          <mat-checkbox formControlName="siteVisits">I am interested in site visits.</mat-checkbox>
          <mat-checkbox formControlName="terms" >I agree to the Terms & Privacy Policy</mat-checkbox>
        </p>
      </div>
      <div class="button-container">
        <button class="button1" [disabled]="!contactForm.get('terms').value"
        [ngClass]="{'inactive-button': !contactForm.get('terms').value}">
        Submit
        </button>
      </div>
    </div>
  </div>
</form>
