import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { HomeService } from 'src/app/_services/home.service';
import { ContactemailComponent } from 'src/app/contactemail/contactemail.component';
import Swal from 'sweetalert2';
import { environmentbasic } from 'src/environments/environment-basic';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  disableEmail: boolean = false;
  EmailId: string | null = null;
  myForm: FormGroup;
  submitted = false;
  isEmailVerified: boolean = false;
  isProfileEmail: boolean = false; // New flag to check if email is from profile

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private authService: AuthService,
    private homeService: HomeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.myForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      mobileNumber: [''],
      description: ['', Validators.required]
    });

    const profileData = localStorage.getItem('UserProfile');

    if (profileData) {
      const parsedProfileData = JSON.parse(profileData);
      this.myForm.patchValue({
        email: parsedProfileData.payload.email,
      });
      this.isEmailVerified = true; // Enable the button if profile data exists
      this.isProfileEmail = true;  // Set flag to true if email is from profile
    }
  }

  onEmailInputBlur(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    const emailId = inputElement.value;

    if (!emailId) {
      console.error("Email ID is empty or undefined.");
      return;
    }

    this.authService.sendemailotp(emailId).subscribe(
      (res: any) => {
        if (res && res.status === 'SUCCESS') {
          this.showModal(emailId);
        } else {
          console.error("Unexpected response:", res);
        }
      },
      error => {
        console.error("Error sending email OTP:", error);
      }
    );
  }

  showModal(email: string): void {
    const dialogRef = this.dialog.open(ContactemailComponent, {
      width: '450px',
      data: { email: email },
      disableClose: false
    });

    dialogRef.componentInstance.emailVerified.subscribe((status: boolean) => {
      if (status) {
        this.disableEmail = true;
        this.EmailId = email;
        this.myForm.get('email')?.setValue(email);
        this.isEmailVerified = true; // Set email as verified when successful
      }
    });
  }

  get f() {
    return this.myForm.controls;
  }

  onSubmit() {
    if (this.myForm.valid) {
      const payload = this.myForm.value;
      this.postQuery(payload);
    } else {
      console.error('Form is invalid');
    }
  }

  postQuery(payload: any) {
    this.homeService.postQuery(payload).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {


          Swal.fire({
            icon: 'success',
            title: 'Your Query Has Been Successfully Submitted.',
            showConfirmButton: false,
            timer: environmentbasic.timer
          });


          this.router.navigate(['/']);
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
}
