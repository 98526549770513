<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu>
    <div class="master master12 py-1 px-xxl-4 px-3 mb-5">

      <div class="page-heading d-flex justify-content-between align-items-center mt-md-2 mb-2">
        <span class="text-start " style="color: #004AAD;">My Inventory</span>
        <button class="btn btn-primary ms-auto" (click)="navigateToAddInventory()">Add Inventory</button>
    </div>
    
    
      
      <div style="cursor: pointer;" class="card-container" *ngIf="topList.length > 0">
          <ng-container *ngFor="let card of topList">
              <app-top-property-card
              [cardData]="card"
              [showEditDetailsButton]="false"
              [showViewDetailsButton]="false"
              [showInventoryButton]="false"
              [showEditInventoryButton]="true">
            </app-top-property-card>
          </ng-container>
        </div>
        <div *ngIf="topList.length === 0">
          <p>No projects available.</p>
        </div>
  
      
  
  </div>
</div>
  <app-footer></app-footer>
</div>
