<div class="custom-share-buttons" style="background-color: #004AAD; padding: 0px !important;">
    <share-buttons
      [theme]="'circles-dark'"
      [include]="['facebook','twitter','linkedin','whatsapp','pinterest','reddit','mix','vk','telegram','messenger','xing','line','sms','email','copy']"
      [show]="9"
      [size]="1"
      [showText]="true"
      [url]="listingUrl"
      [title]="listingTitle"
      [description]="listingDescription + ' Price: ' + listingPrice + ', Area: ' + listingArea"
      [image]="listingImage"
      [autoSetMeta]="true"
    >
    </share-buttons>
  </div>
  