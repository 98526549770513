import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { HomeService } from 'src/app/_services/home.service';
import { EmailverifyComponent } from 'src/app/verifymodel/emailverify/emailverify.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
	disableEmail: boolean = false;
	EmailId: string | null = null;
  myForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
		public dialog: MatDialog,
    private authService: AuthService,
    private homeService: HomeService,
    private router: Router,

  ) {}

  ngOnInit() {

 

    this.myForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      phone: [''],
      description: ['', Validators.required]
    });

    const profileData = localStorage.getItem('UserProfile');

    if (profileData) {
      const parsedProfileData = JSON.parse(profileData);
      console.log('parsedProfileData',parsedProfileData);
      this.myForm.patchValue({
        email: parsedProfileData.payload.email,
       
      });
    }
   
  }

  get f() {
    return this.myForm.controls;
  }

  onSubmit() {
    const controls = this.myForm.controls;

    for (const i in controls) {
      if (controls.hasOwnProperty(i)) {
        controls[i].markAsDirty();
        controls[i].updateValueAndValidity();
      }
    }

    if (this.myForm.valid) {
      const payload =this.myForm.value
      this.postQuery(payload);
    } else {
      console.error('Form is invalid');
    }
  }

  postQuery(payload: any) {
    this.homeService.postQuery(payload).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          console.log(response);
          this.router.navigate(['/'])
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }
  // onEmailInputBlur(event: FocusEvent) {
	// 	const inputElement = event.target as HTMLInputElement;
	// 	const emailId = inputElement.value;
	  
	// 	console.log("emailId", emailId);
	// 	if (!emailId) {
	// 	  console.error("Email ID is empty or undefined.");
	// 	  return;
	// 	}
	// 	this.authService.sendemailotp(emailId).subscribe((res: any) => {
	// 	  console.log(res);
	  
	// 	  if (res && res.status === 'SUCCESS') {
	// 		this.showModal(emailId);
	// 		console.log('res', res);
	// 	  } else {
	// 		console.error("Unexpected response:", res);
	// 	  }
	// 	}, error => {
	// 	  console.error("Error sending email OTP:", error);
	// 	});
	//   }

	  // showModal(email: string): void {
		// const dialogRef = this.dialog.open(EmailverifyComponent, {
		//   width: '450px',
		//   data: { email: email },
		//   disableClose: false
		// });
	
		// dialogRef.componentInstance.emailVerified.subscribe((status: boolean) => {
		//   if (status) {
		// 	this.disableEmail = true;
		// 	this.EmailId = email;
		// 	// this.regform.get('email')?.disable();
		// 	this.myForm.get('email')?.setValue(email);
		//   }
		// });
	  // }

}
