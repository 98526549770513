import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-share-modal',
  templateUrl: './share-modal.component.html',
  styleUrls: ['./share-modal.component.scss']
})
export class ShareModalComponent {
  listingUrl: string;
  listingTitle: string;
  listingDescription: string;
  listingImage: string;
  listingPrice:any;
  listingArea:any;
  encapsulation: ViewEncapsulation.None
 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {

    
    this.listingUrl = data.listingUrl;
    this.listingTitle = data.listingTitle;
    this.listingDescription = data.listingDescription;
    this.listingImage = data.listingImage;
    this.listingPrice=data.listingPrice;
    this.listingArea=data.listingArea;
  }

  ngOnInit(): void {
   
  console.log('Received card data:', this.data);
   console.log( this.listingTitle);
   this.listingDescription=this.stripHtmlTags(this.listingDescription);
   
  }
  stripHtmlTags(input: string): string {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.body.textContent || "";
  }
}
