import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import Swal from 'sweetalert2';
import { JobService } from '../_services/job.service';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import {environmentbasic} from '../../environments/environment-basic';
@Component({
	selector: 'app-contract-detail',
	templateUrl: './contract-detail.component.html',
	styleUrls: ['./contract-detail.component.css'],
	encapsulation: ViewEncapsulation.None,
})
export class ContractDetailComponent implements OnInit {
	milestoneDetailsForm: FormGroup;
	public modalRef: BsModalRef;
	constructor(
		private jobService: JobService,
		private formBuilder: FormBuilder,
		private modalService: BsModalService,
		private tokenStorage: TokenStorageService,
		private route: ActivatedRoute,
		private router: Router) { }

	page = 1;
	count = 0;
	tableSize = 4;
	contract: any = {};
	milestones: any = [];
	userinfo: any = [];
	contractId: string = '';
	ngOnInit(): void {
		AOS.init();
		this.userinfo = JSON.parse(this.tokenStorage.getUser());
		this.route.params.subscribe(val => {
			if (val && val.contractId) {
				this.contractId = val.contractId;
			}
		});

		this.jobService.getContractDetail(this.contractId).subscribe(
			response => {
				if (response['status'] == 'SUCCESS') {
					if (response['payload']) {
						this.contract = response['payload'];
					}
				}
			});
		this.jobService.getAllMilestonesOfContract(this.contractId, this.page, this.tableSize).subscribe(
			response => {
				if (response['status'] == 'SUCCESS') {
					if (response['payload']['milestones'] && response['payload']['milestones'].length > 0) {
						this.milestones = response['payload']['milestones'];
						this.count = response['payload']['total'];
						this.tableSize = response['payload']['limit'];
						this.page = response['payload']['pageNumber'];
					}
				}
			});
		this.milestoneDetailsForm = this.formBuilder.group({
			id: [''],
			milestoneNumber: [''],
			milestoneDetail: ['']
		});
	}

	onTableDataChange(event) {
		this.page = event;
		this.jobService.getAllMilestonesOfContract(this.contractId, this.page, this.tableSize).subscribe(
			response => {
				if (response['status'] == 'SUCCESS') {
					if (response['payload']['milestones'] && response['payload']['milestones'].length > 0) {
						this.milestones = response['payload']['milestones'];
						this.count = response['payload']['total'];
						this.tableSize = response['payload']['limit'];
						this.page = response['payload']['pageNumber'];
					}
				}
			});
	}
	prepareMilestone(releaseContractTemplate: TemplateRef<any>){
		this.modalRef = this.modalService.show(releaseContractTemplate);
	}
	addMilestones() {
		let jobData = this.milestoneDetailsForm.value;
		let requestData = {
			"clientCandidateJobContract": {
				"id": this.contractId
			},
			"status":"new",
			"milestoneNumber":jobData.milestoneNumber,
			"milestoneDetail":jobData.milestoneDetail
		};
		this.jobService.createMilestone(requestData).subscribe(
			response => {
				if (response['status'] == 'SUCCESS') {
					Swal.fire({
						icon: 'success',
						title: 'milestone created successfully.',
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
					window.location.reload();
				} else {
					Swal.fire({
						icon: 'error',
						title: response['message'],
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
				}
			}
		);
	}

	submitMilestones(contractId) {

	}
}
