import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute,Event, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { filter } from 'rxjs/operators';
import { HomeService } from '../_services/home.service';
import { HeaderSearchService } from '../_services/header-search.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
	@ViewChild('searchkey') searchInput: ElementRef;
	constructor(private route: ActivatedRoute,
		private authService: AuthService,
		private tokenStorage: TokenStorageService,
		private homeService: HomeService,
		private router: Router,
		private searchService: HeaderSearchService) {
	}
	searchTerm = 'artwork';
	dropdownOpen = false;
	usertoken: any = [];
	token = '';
	userinfo: any = [];
	socialOrigin = '';
	itsMe = false;
	userid = '';
	unReadCount=0;
	collapsed:boolean = false
	builder: boolean | string = ''; 
	// collapsed3:boolean = false
	collapsed1:boolean = false;
	isKhubiiAdmin=false;
	isMenushow: boolean = false
	userfullname: string = '';
	useremail = '';
	@ViewChild('navbarToggler') navbarToggler: ElementRef;
	navBarTogglerIsVisible() {
		return this.navbarToggler?.nativeElement.getAttribute('aria-expanded')
	}
	@ViewChild('navbarToggler1') navbarToggler1: ElementRef;
	navBarTogglerIsVisible1() {
		return this.navbarToggler1?.nativeElement.getAttribute('aria-expanded')
	}
	navigateToUserProprty(){
		
		this.router.navigate(['/UserProperty'])
	}
	navigateToUserProjects(){
		
		this.router.navigate(['/myprojects'])
	}
	
	toggleDropdown() {
		this.dropdownOpen = !this.dropdownOpen;
	  }
	
	  selectOption(option: string) {
		console.log(`Selected option: ${option}`);
		this.dropdownOpen = false;
	  }
	  populateForm() {
		const profileData = localStorage.getItem('auth-user');
		  const parsedProfileData1 = JSON.parse(profileData);
		  const parsedProfileData = JSON.parse(parsedProfileData1);
		  this.userfullname = parsedProfileData.fullName;
		  this.useremail = parsedProfileData.email;
		  let useremail = this.useremail
		  console.log("useremail",useremail);
	      console.log("userfullname",this.userfullname);
		  let userfullname = this.userfullname
		  }
    
	ngOnInit(): void {
		let Userdata = localStorage.getItem("UserProfile");
		if (Userdata) {
		  let parsedUserdata = JSON.parse(Userdata);
		  this.builder = parsedUserdata.payload.builder;
		}
		  

		try { this.userinfo = JSON.parse(this.tokenStorage.getUser()); } catch (e) { }
		if (this.userinfo.id) {
      this.itsMe = true;
		} else {
			this.itsMe = false;
		}
		
		this.router.events.pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
		//this.router.events.pipe(filter(evt => evt instanceof NavigationEnd))
			//.subscribe((evt: NavigationEnd) => {
				// this.collapseNav()
				// this.populateForm();

			});
		try {
			this.usertoken = JSON.parse(this.tokenStorage.getToken());
			this.socialOrigin = this.tokenStorage.getSocialOrigin();
			this.useremail = this.tokenStorage.getSocialUserEmail();
			if(this.userinfo.email=='admin@khubii.com'){
				this.isKhubiiAdmin=true;
			}
			if (this.usertoken && this.usertoken.access_token) {
				this.token = this.usertoken.access_token;
				this.authService.getUser(this.token, this.socialOrigin, this.useremail).subscribe(
					response => {
						if (response['status'] == 'SUCCESS') {
							this.userinfo = response['payload'];
							console.log("in header:-"+this.userinfo)
							this.tokenStorage.saveUser(JSON.stringify(this.userinfo));
							this.populateForm();
						}
					});
			}
		} catch (err) { }

	}
	
	
	searchArt() {
		const searchData = this.searchInput.nativeElement.value;
		console.log(searchData);
		this.searchService.setSearchData(searchData);
	  }
	  


	  userLogout() {
		this.tokenStorage.signOut();
		this.router.navigate(['/login']).then(() => {
			window.location.reload();
		});
	}
	newsnavigate(){
		this.router.navigate(['/news']).then(() => {
		  window.location.reload();
		});
	  }

}
