import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../_services/token-storage.service';
import { environmentbasic } from '../../environments/environment-basic';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from "angularx-social-login";
import { EmailverifyComponent } from '../verifymodel/emailverify/emailverify.component';
import { MatDialog } from '@angular/material/dialog';
import { HomeService } from '../_services/home.service';
import { switchMap } from 'rxjs/operators';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

	constructor(
		private formBuilder: FormBuilder,
        private homeService: HomeService,
		public dialog: MatDialog,
		private authService: AuthService,
		private tokenStorage: TokenStorageService,
		private socialauthService: SocialAuthService,
		private router: Router) { }
	ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9_]*$/;
	swapclass = '';
	aciveforgot = false;
	activereset = false;
	regform: FormGroup;
	otpform: FormGroup;
	loginform: FormGroup;
	forgotform: FormGroup; unique
	id!: string;
	loading = false;
	submitted = false;
	otpactive = false;
	registrationactive = true;
	uservalid = '';
	otpvalid = '';
	useremail = '';
	dropdownSettings = {};
	GoogleLoginProvider = GoogleLoginProvider;
	user: any;
	userinfo: any = [];
	countries = ['USA', 'Canada', 'UK', 'Australia', 'India']; // Add m
	// toppingList: string[] = ['1', '2', '3','4'];
	
	toppingList = [
		{ id: 1, value: 1 },
		{ id: 2, value: 2 }
	  ];
	  toppingList1 = [
		{ id: 1 ,value: 1 },
		{ id: 2, value: 2}
	  ];
	
	disableEmail: boolean = false;
	EmailId: string | null = null;

	onUserTypeChange(userType: string) {
		this.regform.patchValue({
		  individual: userType === 'individual',
		  builder: userType === 'builder',
		  agent: userType === 'agent'
		});
	  }
	ngOnInit(): void {

		console.log("in login page");

		this.regform = this.formBuilder.group({
			fullName: ['', [Validators.required]],
			email: ['', [Validators.required, Validators.email]],
			mobileNumber: ['', [Validators.required, Validators.pattern(/^[0-9]+$/)]],
			password: ['', [Validators.required, Validators.minLength(6)]],
		    userType: [null, Validators.required], 
		  });
		
		this.otpform = this.formBuilder.group({
			otp: ['', [Validators.required, Validators.minLength(6)]]
		});
		this.loginform = this.formBuilder.group({
			emailusernam: ['', [Validators.required, Validators.minLength(8)]],
			loginpassword: ['', [Validators.required, Validators.minLength(6)]]
		});
		this.forgotform = this.formBuilder.group({
			forgotemail: ['', [Validators.required, Validators.email]],
		});

		this.socialauthService.authState.subscribe((response) => {
			this.user = response;
			if (this.user) {
				let token = null;
				if (this.user.provider == 'FACEBOOK') {
					token = this.user.authToken;
				}
				if (this.user.provider == 'GOOGLE') {
					token = this.user.idToken;
				}
				let tokenInStorage = { 'access_token': token };
				this.tokenStorage.saveToken(JSON.stringify(tokenInStorage));
				this.tokenStorage.saveSocialOrigin(this.user.provider);
				this.tokenStorage.saveSocialUserEmail(this.user.email);
				Swal.fire({
					icon: 'success',
					title: 'User verifcation is completed.',
					showConfirmButton: false,
					timer: environmentbasic.timer
				});
				window.location.href = '/';
			} else {
				Swal.fire({
					icon: 'error',
					title: response['message'],
					showConfirmButton: false,
					timer: environmentbasic.timer
				});
			}
		});
	}

	get f() { return this.regform.controls; }
	get g() { return this.otpform.controls; }
	get h() { return this.loginform.controls; }
	get k() { return this.forgotform.controls; }

	onSubmit() {
		this.submitted = true;
		if (this.regform.invalid) {
		  return;
		}
	  
		let formData = this.regform.value;
	  
		const { individual, builder, agent } = formData.userType;
		formData = {
		  ...formData,
		  individual,
		  builder,
		  agent
		};
	  
		delete formData.userType;
	  
		console.log("regform", formData);
	  
		this.uniqueUsername(formData);
		localStorage.setItem('profileData', JSON.stringify(formData));
	  }
	  

	
	onSubmitOtp() {
		this.otpvalid = '';
		this.submitted = true;

		if (this.otpform.invalid) {
			return;
		}

		this.authService.verifyOtp(this.useremail, this.otpform.value.otp).subscribe(

			response => {
				if (response['status'] == 'SUCCESS') {
					this.registrationactive = false;
					this.otpactive = true;
					Swal.fire({
						icon: 'success',
						title: 'User verifcation is completed.',
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
					window.location.reload();
				} else {
					Swal.fire({
						icon: 'error',
						title: response['message'],
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
				}
			});
	}
	onLoginSubmit() {
		this.loading = true;
		this.submitted = true;
		
		if (this.loginform.invalid) {
			this.loading = false;
			return;
		}
	
		this.authService.loginUser(this.loginform.value.emailusernam, this.loginform.value.loginpassword)
			.pipe(
				switchMap(response => {
					console.log(response);
	
					if (response && response.status === "SUCCESS") {
						console.log("hello");
						console.log('response->', response);
						let accessT = response.payload.access_token;
						const accessToken = response.payload;
						this.tokenStorage.saveToken(JSON.stringify(accessToken));
						const refreshToken = response.payload.refresh_token;
						localStorage.setItem("access_token", accessT);
						const expiresIn = response.payload.expires_in;
						localStorage.setItem("refresh_token", refreshToken);
						localStorage.setItem("expires_in", expiresIn);
						this.tokenStorage.saveSocialOrigin(null);
						this.tokenStorage.saveSocialUserEmail(null);
						this.tokenStorage.saveFirstVisitToHomePageAfterLogin("firstVisit");
	
						// Use switchMap to chain the profile data retrieval
						return this.authService.getProfileData();
					} else {
						Swal.fire({
							icon: 'error',
							title: response['error_description'],
							showConfirmButton: false,
							timer: environmentbasic.timer
						});
						this.loading = false;
						throw new Error('Login failed');
					}
				})
			)
			.subscribe(
				response => {
					
					console.log('response11->', response);
					if (response) {
						let UserProfile =response
						localStorage.setItem("UserProfile", JSON.stringify(UserProfile));
                        console.log("UserProfile retrieved successfully");
					} else {
						console.log("Failed to retrieve user profile");
					}
					this.loading = false;
	
					Swal.fire({
						icon: 'success',
						title: 'You logged in successfully.',
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
					window.location.href = '/';
				},
				error => {
					this.loading = false;
					console.error('An error occurred', error);
				}
			);
	}
    //   UserProfile(access:any){
	// 	this.homeService.userProfile(access).subscribe(
	// 		response => {
	// 			console.log("hello");
	// 			console.log(response);
	// 			const responseString = JSON.stringify(response);

	// 			localStorage.setItem("response", responseString);

	// 			// if (response && response.status==="SUCCESS") {
	// 				console.log("hello");
	// 				console.log('response->',response);
					
	// 			// } else {
					
	// 			// }
	// 			this.loading = false;
	// 		});

	//   }


	signUpSwap() {
		this.swapclass = 'right-panel-active';
	}

	signInSwap() {
		this.swapclass = '';
	}
	uniqueUsername(formdata) {
		console.log(formdata);

		this.loading = true;


		this.authService.userRegister(formdata).subscribe(
			response => {

				console.log(formdata);
				
				if (response['status'] == 'SUCCESS') {
					// this.registrationactive = false;
					this.signInSwap()
					// this.otpactive = true;
					// this.useremail = response['payload']['email'];
					// this.tokenStorage.saveFirstVisitToHomePageAfterLogin("firstVisit");
					Swal.fire({
						icon: 'success',
						title: response['payload']['fullName'] + ' is registed successfully.',
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
				} else {
					Swal.fire({
						icon: 'error',
						title: response['message'],
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
				}
				this.loading = false;
			});

		this.loading = false;
	}
	forgotSwitch() {
		this.aciveforgot = true;
	}

	loginSwitch() {
		this.aciveforgot = false;
	}
	onForgotSubmit() {
		this.loading = true;
		this.submitted = true;
		if (this.forgotform.invalid) {
			return;
		}
		this.authService.forgotPassword(this.forgotform.value.forgotemail).subscribe(
			response => {
				if (response['status'] == 'SUCCESS') {
					Swal.fire({
						icon: 'success',
						title: 'OTP shared over your registered email,please check your inbox.',
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
					this.router.navigate(['/setnewpassword/' + this.forgotform.value.forgotemail]);
				} else {
					Swal.fire({
						icon: 'error',
						title: response['message'],
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
				}
				this.loading = false;
			});
	}
	signInWithFB(): void {
		this.socialauthService.signIn(FacebookLoginProvider.PROVIDER_ID);
	}

	signOut(): void {
		this.socialauthService.signOut();
	}

	signInWithGoogle(): void {
		this.socialauthService.signIn(GoogleLoginProvider.PROVIDER_ID);
	}

	
	
	
	  verifyOtp(email: string, otp: string): void {
		this.authService.verifyOtp(email, otp).subscribe(response => {
			console.log(response);
			
		//   if (response.status === 'SUCCESS') {
		// 	// Handle success
		// 	console.log('OTP verified successfully');
		//   } else {
		// 	// Handle error
		// 	console.error('OTP verification failed');
		//   }
		});
	  }
	//   onEmailInputBlur(email_Id: string) {
  
	// 	console.log("email_Id", email_Id);
	// 	if (!email_Id) {
	// 	  console.error("Email ID is empty or undefined.");
	// 	  return;
	// 	}
	// 	this.authService.sendemailotp(email_Id).subscribe((res: any) => {
	// 		console.log(res);
			
	// 	  if (res && res.status === 'SUCCESS') {
	// 		this.showModal(email_Id);
	// 		console.log('res',res);
			
	// 	  } else {
	// 		console.error("Unexpected response:", res);
	// 	  }
	// 	}, error => {
	// 	  // Handle error
	// 	});
	//   }
	onEmailInputBlur(event: FocusEvent) {
		const inputElement = event.target as HTMLInputElement;
		const emailId = inputElement.value;
	  
		console.log("emailId", emailId);
		if (!emailId) {
		  console.error("Email ID is empty or undefined.");
		  return;
		}
		this.authService.sendemailotp(emailId).subscribe((res: any) => {
		  console.log(res);
	  
		  if (res && res.status === 'SUCCESS') {
			this.showModal(emailId);
			console.log('res', res);
		  } else {
			console.error("Unexpected response:", res);
		  }
		}, error => {
		  // Handle error
		  console.error("Error sending email OTP:", error);
		});
	  }

	  showModal(email: string): void {
		const dialogRef = this.dialog.open(EmailverifyComponent, {
		  width: '450px',
		  data: { email: email },
		  disableClose: false
		});
	
		dialogRef.componentInstance.emailVerified.subscribe((status: boolean) => {
		  if (status) {
			this.disableEmail = true;
			this.EmailId = email;
			// this.regform.get('email')?.disable();
			this.regform.get('email')?.setValue(email);
		  }
		});
	  }

	}

