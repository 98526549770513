import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridApi, GridOptions } from 'ag-grid-community';
import { HomeService } from '../_services/home.service';
import { ActionComponent } from '../Action/action.component';

@Component({
  selector: 'app-project-inventory',
  templateUrl: './project-inventory.component.html',
  styleUrls: ['./project-inventory.component.scss']
})
export class ProjectInventoryComponent implements OnInit {
  projectId: string | null = null;
  IsLoading: boolean = false;
  topList: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private homeService: HomeService
  ) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      this.projectId = params.get('projectId');
      console.log('Received ProjectId:', this.projectId);
      if (this.projectId) {
        localStorage.setItem('projectId', this.projectId);
      }

      this.projectId= localStorage.getItem("projectId")
      console.log('localprojectId',this.projectId);
      
      this.apiService();
    });
  }

  navigateToAddInventory() {
    this.router.navigate(['/add-invetory'], {
      queryParams: { projectId: this.projectId }
    });
  }

  stripHtmlTags(input: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, 'text/html');
    return doc.body.textContent || '';
  }

  
  apiService() {
    this.IsLoading = true;
    this.homeService.getInventoryByProjectId(this.projectId).subscribe((res: any) => {
      this.IsLoading = false;
        if (res.status === 'SUCCESS' && res.payload) {
          this.topList = res.payload; 
          console.log("toplistaaaaa",this.topList)
        } else {
          this.topList = []; // Handle the case when no data is available
        }
        
    },
    () => {
      this.IsLoading = false;
      this.topList = []; // Handle error case
    });
  }

  
  ngOnDestroy() {
  
  }

  
}
