import { Component, OnInit, ElementRef, ViewChild ,AfterViewInit} from '@angular/core';
import {latLng, MapOptions, tileLayer} from 'leaflet';

@Component({
  selector: 'app-argmap',
  templateUrl: './argmap.component.html',
  styleUrls: ['./argmap.component.scss']
})
export class ArgmapComponent implements OnInit {
  title = 'google-maps';
  mapOptions: MapOptions;
  @ViewChild('mapRef', {static: true }) mapElement: ElementRef;

  constructor() { }

  ngOnInit(): void {
   
  
    this.renderMap();
    //this.initializeMapOptions();
  }
  private initializeMapOptions() {
    this.mapOptions = {
      center: latLng(51.505, 0),
      zoom: 12,
      layers: [
        tileLayer(
          'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          {
            maxZoom: 18,
            attribution: 'Map data © OpenStreetMap contributors'
          })
      ],
    };
  }

  loadMap = () => {
    var map = new window['google'].maps.Map(this.mapElement.nativeElement, {
      center: {lat: 24.5373, lng: 81.3042},
      zoom: 8
    });
  
    var marker = new window['google'].maps.Marker({
      position: {lat: 24.5373, lng: 81.3042},
      map: map,
      title: 'Hello World!',
      draggable: true,
      animation: window['google'].maps.Animation.DROP,
    });
    var contentString = '<div id="content">'+
    '<div id="siteNotice">'+
    '</div>'+
    '<h3 id="thirdHeading" class="thirdHeading">tutsmake.com</h3>'+
    '<div id="bodyContent">'+
    '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>'+
    '</div>'+
    '</div>';
  
    var infowindow = new window['google'].maps.InfoWindow({
      content: contentString
    });
  
      marker.addListener('click', function() {
        infowindow.open(map, marker);
      });
  
  }
  renderMap() {
      
    window['initMap'] = () => {
      this.loadMap();      
    }
    if(!window.document.getElementById('google-map-script')) {
      var s = window.document.createElement("script");
      s.id = "google-map-script";
      s.type = "text/javascript";
      s.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyC6B4-_NZIsVKB4W9qDLm_IYCxuei7tZPo&callback=initMap";
        
      window.document.body.appendChild(s);
    } else {
      this.loadMap();
    }
  }

}
