<div class="card" *ngIf="cardData.type !== 'viewAll'; else viewAllCard">
  <div class="row col-12" (click)="viewDetails($event)">
    <!-- <img *ngIf="cardData?.projectPlanImage?.length > 0; else fallbackImage" 
         [src]="cardData.projectPlanImage[0].url" 
         class="Images">
         <img *ngIf="cardData?.bannerImage.length > 0; else fallbackImage" 
         [src]="cardData.bannerImage[0].url" 
         class="Images">
    (click)="viewDetails($event)"
    <ng-template #fallbackImage>
      <img src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg" class="Images">
    </ng-template>
  </div> -->

    <img *ngIf="cardData?.projectPlanImage?.length > 0; else checkBanner" 
         [src]="cardData.projectPlanImage[0].url" 
         class="Images">
  
    <ng-template #checkBanner>
      <img *ngIf="cardData?.inventoryImage?.length > 0; else fallbackImage" 
           [src]="cardData.inventoryImage[0].url" 
           class="Images">
    </ng-template>
  
    <ng-template #fallbackImage>
      <img src="https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg" 
           class="Images">
    </ng-template>
  </div>
  
  
  
  <div class="row col-12" style="margin-top: 15px;">
    <div class="col-md-12 d-flex flex-column justify-content-start" (click)="viewDetails($event)">
      <span class="title">{{ cardData.title | capitalize }}</span>
    </div>
  </div>
  
  <div class="row col-12" >
    <div class="col-md-12 d-flex flex-column justify-content-start" matTooltip="{{ cardData.description | stripHtml }}">
      <span class="-text" [innerHTML]="(cardData.description | truncateWords: 12)"></span>
    </div>
  </div>
  
  <div class="row col-12">
    <div class="col-md-12 d-flex flex-column justify-content-start">
      <span class="title">₹{{ formatPrice(cardData.fromPrice) }} - {{ formatPrice(cardData.toPrice) }}</span>
    </div>
  </div>
  
  <div class="col-md-12">
    <div>{{ cardData.details }}</div>
    <div class="target">{{ cardData.cityName }} &nbsp;{{ cardData.stateName }}</div>
  </div>
  
  <div class="col-md-12">
    <span class="title">{{ cardData.price }}</span>
  </div>

  <!-- <div class="col-md-12">

    <button *ngIf="showEditDetailsButton" class="btn btn-primary mb-2 " (click)="editDetails($event)">Edit Details</button>
    <button *ngIf="showViewDetailsButton" class="btn btn-secondary mb-2" (click)="viewDetails($event)">View Details</button>
    <button *ngIf="showInventoryButton" class="btn btn-info mb-2" (click)="inventory($event)">Inventory</button>
    <!-- <button *ngIf="showEditInventoryButton" class="btn btn-info mb-2" (click)="editInventory($event)">Edit Inventory</button> -->
    <!-- <button  class="btn btn-info mb-2" (click)="editInventory($event)">Edit Inventory</button> -->

  
  <!-- </div>  -->
  <div class="col-md-12 button-container">
    <button *ngIf="showEditDetailsButton" class="custom-btn mb-2" (click)="editDetails($event)">Edit Details</button>
    <button *ngIf="showViewDetailsButton" class="custom-btn mb-2" (click)="viewDetails($event)">View Details</button>
    <button *ngIf="showInventoryButton" class="custom-btn mb-2" (click)="inventory($event)">Inventory</button>
    <button *ngIf="showEditInventoryButton" class="custom-btn mb-2" (click)="editInventory($event)">Edit Inventory</button>
  </div>
  
  

</div>

<ng-template #viewAllCard>
  <div class="view-all-card" (click)="navigateTOListing()">
    <mat-icon class="view-all-icon">arrow_forward</mat-icon> 
    <span >View All</span>
  </div>
</ng-template>