import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform {

  transform(value: string | Date): string {
    if (!value) return 'No date provided';

    // Parse the date string if it's in the "DD-MM-YYYY HH:mm:ss" format
    let date: Date;
    if (typeof value === 'string') {
      const [day, month, year, hours, minutes, seconds] = value.split(/[- :]/);
      date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
    } else {
      date = new Date(value);
    }

    if (isNaN(date.getTime())) return 'Invalid date';
    const now = new Date();
    const adjustedNow = new Date(now.getTime() - (5 * 60 * 60 * 1000 + 30 * 60 * 1000));

    const diffMs = adjustedNow.getTime() - date.getTime();
    const diffYears = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365));
    const diffMonths = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 30));
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHrs = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

    if (diffYears > 0) {
      return `${diffYears} year${diffYears !== 1 ? 's' : ''} ago`;
    } else if (diffMonths > 0) {
      return `${diffMonths} month${diffMonths !== 1 ? 's' : ''} ago`;
    } else if (diffDays > 0) {
      return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
    } else if (diffHrs > 0) {
      return `${diffHrs} hour${diffHrs !== 1 ? 's' : ''} ago`;
    } else if (diffMins > 0) {
      return `${diffMins} minute${diffMins !== 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  }
}
