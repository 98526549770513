import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-property-card-semi-small',
  templateUrl: './top-property-card-semi-small.component.html',
  styleUrls: ['./top-property-card-semi-small.component.scss']
})
export class TopPropertyCardSemiSmallComponent implements OnInit {

  listing:any
  Images:any
  constructor(
    private router: Router
  ) { }

  @Input() cardData: any;
  

  ngOnInit(): void {
    console.log('Trending Card',this.cardData);
    
  }

  formatPrice(value: number | null | undefined): string {
    if (value == null) {
      return ''; 
    }
  
    if (value >= 10000000) {
      return `${(value / 10000000).toFixed(2)} Cr`;
    } else if (value >= 100000) {
      return `${(value / 100000).toFixed(2)} L`;
    } else {
      return value.toLocaleString('en-IN');
    }
  }
  navigateTOListing(){
    this.router.navigate(['/Trending-project-list']);
  }
}
