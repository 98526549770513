<div class="wrapper">
  <app-header></app-header>
  <div class="content">
    <app-menu></app-menu><br>
    <div style="cursor: pointer;" class="card-container master12" *ngIf="topList.length > 0" >
      <ng-container *ngFor="let card of topList">
        <app-top-property-card
        [cardData]="card"
        [showEditDetailsButton]="true"
        [showViewDetailsButton]="true"
        [showInventoryButton]="true"
        [showEditInventoryButton]="false">
      </app-top-property-card>
      </ng-container>
    </div>
    <div *ngIf="topList.length === 0">
      <p>No projects available.</p>
    </div><br>
  </div>
  <app-footer></app-footer>
 </div>


