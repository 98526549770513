import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { JobService } from '../_services/job.service';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import { HomeService } from '../_services/home.service';
import { ArtService } from '../_services/art.service';
import { filter } from 'rxjs/operators';
import {environmentbasic} from '../../environments/environment-basic';
@Component({
	selector: 'app-search-artist',
	templateUrl: './search-artist.component.html',
	styleUrls: ['./search-artist.component.css']
})
export class SearchArtistComponent implements OnInit {
	constructor(
		private homeService: HomeService,
		private tokenStorage: TokenStorageService,
		private route: ActivatedRoute,
		private artService: ArtService,
		private router: Router) { }

	page = 1;
	count = 0;
	tableSize = 10;
	expertProfiles: any = [];
	artistFollow: any = [];
	searchString: string = '';
	userinfo: any = [];
	profilePic: string = '../assets/images/profile1.png';
	userid = null;
	artParama: any = { "limit": this.tableSize, "offset": 0, "searchString": "" };
	ngOnInit(): void {
		this.initMethod();
		this.router.events.pipe(
            filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
		//this.router.events.pipe(filter(evt => evt instanceof NavigationEnd))
			//.subscribe((evt: NavigationEnd) => {
				this.initMethod()
			});
	}

	initMethod() {
		AOS.init();
		this.artParama['searchString'] = this.route.snapshot.paramMap.get("se");
		this.artParama['offset'] = 0;
		try{this.userinfo = JSON.parse(this.tokenStorage.getUser());}catch(error){}
		this.userid = this.userinfo.id;
		if (this.userid) {
			this.homeService.getUsersAllActivities(this.userid).subscribe(response => {
				if (response['status'] == 'SUCCESS') {
					if (response['payload']['artistFollow'] && response['payload']['artistFollow'].length > 0) {
						this.artistFollow = response['payload']['artistFollow'];
					}
				}

			});
		}
		this.homeService.searchExperts(this.artParama).subscribe(response => {
			if (response['status'] == 'SUCCESS') {
				if (response['payload']['expertProfiles'] && response['payload']['expertProfiles'].length > 0) {
					this.expertProfiles = response['payload']['expertProfiles'];
					this.count = response['payload']['total'];
					this.tableSize = response['payload']['limit'];

				}
			}

		});
	}
	convertToNumeric(userid) {
		return parseInt(userid);
	}
	onTableDataChange(event) {
		this.page = event;
		this.artParama['offset'] = this.tableSize * (this.page - 1);
		this.homeService.searchExperts(this.artParama).subscribe(response => {
			if (response['status'] == 'SUCCESS') {
				if (response['payload']['expertProfiles'] && response['payload']['expertProfiles'].length > 0) {
					this.expertProfiles = response['payload']['expertProfiles'];
					this.count = response['payload']['total'];
					this.tableSize = response['payload']['limit'];
				} else {
					this.expertProfiles = [];
				}
			} else {
				this.expertProfiles = [];
			}

		});
	}
	
	searchArtist(searchkey) {
		console.log(searchkey.value);
		this.artParama['searchString'] = searchkey.value;
		this.artParama['offset'] = 0;
		this.homeService.searchExperts(this.artParama).subscribe(response => {
			if (response['status'] == 'SUCCESS') {
				console.log(response['payload']);
				if (response['payload']['expertProfiles'] && response['payload']['expertProfiles'].length > 0) {
					this.expertProfiles = response['payload']['expertProfiles'];
					this.count = response['payload']['total'];
					this.tableSize = response['payload']['limit'];
				} else {
					this.expertProfiles = [];
				}
			} else {
				this.expertProfiles = [];
			}

		});
	}

	userFollowing(followingid) {
		if (this.userid) {
			this.artService.userFollowing(this.userid, followingid).subscribe(
				response => {
					if (response['status'] == 'SUCCESS') {
						Swal.fire({
							icon: 'success',
							title: 'Successfully start following user.',
							showConfirmButton: false,
							timer: environmentbasic.timer
						});
					}
					window.location.reload();
				});
		} else {
			this.tokenStorage.signOut();
			this.router.navigate(['/login']);
			return;
		}

	}
}
