import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { catchError, concatMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class ArtService {

	token = '';
	usertoken: any = [];
	socialOrigin = '';
	email = '';
	constructor(private tokenStorage: TokenStorageService,
		private http: HttpClient) {
		this.usertoken = JSON.parse(this.tokenStorage.getToken());
		this.socialOrigin = this.tokenStorage.getSocialOrigin();
		this.email = this.tokenStorage.getSocialUserEmail();
		if (this.usertoken && this.usertoken.access_token) {
			this.token = this.usertoken.access_token;
		}
	}

	updateArtsDetails(fdata, userId) {

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		
		return this.http.post(`${environment.apiUrl}/artwork/upload?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					});*/
					return throwError(error);
				})
			);
	

	}

	saveArtsDetails(fdata, userId, challengeId) {

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		if (challengeId != 'null') {
			return this.http.post(`${environment.apiUrl}/artwork/upload?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(fdata), {
				headers: headers
			})
				.pipe(
					concatMap(
						(res) =>
							this.linkWithChallenge(res, res['payload'], userId, challengeId))
				);
		} else {
			return this.http.post(`${environment.apiUrl}/artwork/upload?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(fdata), {
				headers: headers
			})
				.pipe(
					tap(data => { return data }), catchError(error => {
						/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
						return throwError(error);
					})
				);
		}

	}
	linkWithChallenge(res: any, projectId: any, userId: any, challengeId: any): any {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		let varData = `{ 
			"challenge":{
				"id":${challengeId}
			},
			"candidate":{
				"id":${userId}
			},
			"userProject":{
				"id":${projectId}
			}
		}`;
		return this.http.post(`${environment.apiUrl}/challenge/apply?socialOrigin=${this.socialOrigin}&email=${this.email}`, varData, { headers: headers });
	}

	getArtsDetails(pid) {

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/artwork/detail/view?socialOrigin=${this.socialOrigin}&email=${this.email}&projectId=${pid}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}

	getArtsDetailsForUpdate(pid) {

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/artwork/detail?socialOrigin=${this.socialOrigin}&email=${this.email}&projectId=${pid}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}
	userFollowing(owneruid, followinguid) {

		let uname = {};
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/follow?socialOrigin=${this.socialOrigin}&email=${this.email}&accountOwnerUserId=${owneruid}&followingUserId=${followinguid}`, JSON.stringify(uname), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}

	deleteArtwork( projectid) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.delete(`${environment.apiUrl}/artwork/delete?socialOrigin=${this.socialOrigin}&email=${this.email}&projectId=${projectid}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					return throwError(error);
				})
			);
	}

	projectLike(userId, projectidId) {

		let uname = { "baseUser": { "id": userId }, "userProject": { "id": projectidId } };
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/user/like/post?socialOrigin=${this.socialOrigin}&email=${this.email}`, JSON.stringify(uname), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}
	uploadFileToS3(preSignedUrl, file, mimeType) {
		const headers = new HttpHeaders({
			'Content-Type': mimeType
		});
		return this.http.post(preSignedUrl, file, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
							icon: 'error',
							title: 'Invalid request',
							showConfirmButton: false,
							timer: 6000
						});*/
					return throwError(error);
				})
			);
	}
}
