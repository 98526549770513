import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TokenStorageService } from './token-storage.service';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { ChatService } from './chat.service';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class JobService {
	token = '';
	usertoken: any = [];
	key = '';
	constructor(private tokenStorage: TokenStorageService,
		private chatService: ChatService,
		private http: HttpClient) {
		this.usertoken = JSON.parse(this.tokenStorage.getToken());
		if (this.usertoken && this.usertoken.access_token) {
			this.token = this.usertoken.access_token;
		}
	}

	postJob(fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/create`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	updateJob(fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.put(`${environment.apiUrl}/job/update`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'invalid',
						showConfirmButton: false,
						timer: 6000
					});*/
					return throwError(error);
				})
			);
	}

	applyJob(fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/apply`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	updateJobStatus(candidateJobDetail, status) {
		let jobId = candidateJobDetail.jobAssignments.id;
		let candidateId = candidateJobDetail.candidate.id;
		let clientId = candidateJobDetail.client.id;
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.put(`${environment.apiUrl}/job/status/update?jobId=` + jobId + `&candidateId=` + candidateId + `&clientId=` + clientId + `&status=` + status, JSON.stringify({}), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	initiateInterview(candidateJobDetail) {
		let jobId = candidateJobDetail.jobAssignments.id;
		let candidateId = candidateJobDetail.candidate.id
		let clientId = candidateJobDetail.client.id;
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/interview?jobId=` + jobId + `&candidateId=` + candidateId + `&clientId=` + clientId, {
			headers: headers
		})
			.pipe(
				concatMap(
					(res) =>
						this.chatService.initiateChat(candidateJobDetail, res['payload'])));
	}

	publishJob(jobId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/publish?jobId=` + jobId + `&status=published`, JSON.stringify({}), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	archieveJob(jobId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/publish?jobId=` + jobId + `&status=archieved`, JSON.stringify({}), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	likedJob(jobId, candidateId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.put(`${environment.apiUrl}/job/like?jobReferenceId=` + jobId + `&candidateId=` + candidateId, JSON.stringify({}), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getJobDetail(jobReferenceId, jobId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/detail?jobReferenceId=${jobReferenceId}` + `&jobId=` + jobId, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	inviteExpert(fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.put(`${environment.apiUrl}/job/update`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	searchExpert(jobId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/search/experts?refernceJobId=` + jobId, JSON.stringify({}), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	searchJob(searchString, pageNumber, limit) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/search?searchString=` + searchString + `&pageNumber=` + pageNumber + `&limit=` + limit, JSON.stringify({}), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	getAllNewJobsPostedByUser(clientId, status, pageNumber, limit) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/fetch?pageNumber=` + pageNumber + `&limit=` + limit + `&clientId=` + clientId + `&status=` + status, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	releaseContract(fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/contract/release`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	acceptOrRejectContract(id, status) {
		let fdata = {
			"id": id,
			"status": status
		};
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/contract/accept`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	withdrawContract(id, status) {
		let fdata = {
			"id": id,
			"status": status
		};
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/contract/withdraw`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getAllInvitedJobs(candidateId, status, pageNumber, limit) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/invitation/fetch?pageNumber=` + pageNumber + `&limit=` + limit + `&candidateId=` + candidateId + `&status=` + status, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	getAllClientInvitedJobs(clientId, status, pageNumber, limit) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/client/invitation/fetch?pageNumber=` + pageNumber + `&limit=` + limit + `&clientId=` + clientId + `&status=` + status, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	getContractDetail(contractId) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/contract/detail?contractId=` + contractId, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	createMilestone(fdata) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.post(`${environment.apiUrl}/job/milestone/release`, JSON.stringify(fdata), {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getAllMilestonesOfContract(contractId, pageNumber, limit) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/milestone/hold?contractId=` + contractId + `&pageNumber=` + pageNumber + `&limit=` + limit, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	getAllOpenContracts(candidateId, status, pageNumber, limit) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/contract/hold?pageNumber=` + pageNumber + `&limit=` + limit + `&candidateId=` + candidateId + `&status=` + status, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}


	getAllReceivedApplications(clientId, pageNumber, limit, status) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/application/client?pageNumber=` + pageNumber + `&limit=` + limit + `&clientId=` + clientId + `&status=` + status, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getAllReceivedOffers(candidateId, status, pageNumber, limit) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/job/contract/hold?pageNumber=` + pageNumber + `&limit=` + limit + `&candidateId=` + candidateId + `&status=` + status, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}

	getLatestJob(pageNumber, limit) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/challenge/fetch/latest?pageNumber=` + pageNumber + `&limit=` + limit, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
}
