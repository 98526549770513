<div *ngIf="loading$ | async" class="loader-overlay">
    <div class="spinner"></div>
  </div>
<!-- <router-outlet></router-outlet>
<app-footer></app-footer>  -->
<!-- <app-header></app-header> -->
<!-- <main>
  <div *ngIf="loading$ | async" class="loader-overlay">
    <div class="spinner"></div>
  </div>

</main>
<div style="margin-top: 10pc;">
 <app-footer></app-footer> -->
<!-- </div> -->
 
<router-outlet></router-outlet>