<div>
  <button type="button" class="btn border-bottom" (click)="openWorkExperienceUpdateModal(manageWorkExperienceTemplate)">
    <i class="fa fa-pencil" style="font-size:16px;color:#007bff"></i>
  </button>
  &nbsp;
  <button type="button" class="btn border-bottom" (click)="deleteWorkExperience(params2.data.id)"> <i
      class="fa fa-trash" style="font-size:16px;color:#007bff"></i></button>
</div>

<ng-template #manageWorkExperienceTemplate>
  
  <div class="modal-header c-header">
    <h4 class="modal-title w-100">Manage Social Accounts
      <button type="button" class="close pull-right d-block" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h4>
  </div>

  <div class="modal-body c-content">
    <div class="contents">
      <form [formGroup]="workExperienceForm" (ngSubmit)="saveUpdateWorkExperience()">
        <div class="form-row">
          <div class="form-group  col-md-6">
            <label for="socialPlatformId">Company *</label>
            <input type="text" name="companyName" formControlName="companyName" class="form-control">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group  col-md-6">
            <label class="socialContactValue">Position *</label>
            <input type="text" name="position" formControlName="position" class="form-control">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group  col-md-6">
            <label for="socialPlatformId">Description *</label>
            <input type="text" name="description" formControlName="description" class="form-control">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group  col-md-6">
            <label class="socialContactValue">From *</label>
            <input type="date" class="form-control" id="startingFrom" formControlName="startingFrom"
              placeholder="dd-MM-yyyy" min="1970-01-01" max="2030-12-31">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group  col-md-6">
            <label for="socialPlatformId">To *</label>
            <input type="date" class="form-control" id="endingIn" formControlName="endingIn" placeholder="dd-MM-yyyy"
              min="1970-01-01" max="2030-12-31">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group  col-md-6">
            <label class="socialContactValue">Country *</label>
            <select formControlName="country" (change)="changeCountry($event.target)" class="form-control">
              <option [value]='null' disabled>Select country</option>
              <option *ngFor="let country of countries" value="{{country.isoCode}}">{{country.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group  col-md-6">
            <label for="socialPlatformId">City *</label>
            <select name="city" formControlName="city" class="form-control">
              <option [value]="null" disabled>Select city</option>
              <option *ngFor="let city of cities" value="{{city.name}}">{{city.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group  col-md-6">
            <label class="socialContactValue">is Currently working here *</label>
            <input type="checkbox" name="isCurrentlyWorking" formControlName="isCurrentlyWorking" class="form-control">
          </div>
        </div>
        <div class="w-100 mt-2">
          <button type="submit" class="btn btn-danger mr-2 confirm-ti-submit">Submit</button>
          <button type="button" class="btn btn-light" aria-label="Close" (click)="modalRef.hide()">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>