<div class="container-fluid h-100 d-flex justify-content-center align-items-center width: 70pc">
	<div class="contain" id="contain">
		<div class="form-contain sign-in-contain w-100">
			<form [formGroup]="resetform" (ngSubmit)="onResetSubmit()" class="account">
				<span class="form-title">Enter Otp</span>
				<div id="otp" class="form-group text-center">
					<ng-otp-input (onInputChange)="onOtpChange($event)" [config]="{ length: 6 }"></ng-otp-input>
				</div>
				<div class="position-relative">
					<hr class="bg-200 mt-5" />
					<div class="divider-content-center">Set your password</div>
				</div>
				<div class="form-group">
					<input type="password" placeholder="Enter Password" formControlName="resetpassword"
						class="form-control" />
					<div class="errorClass" *ngIf="submitted && l.resetpassword.errors">
						<div *ngIf="l.resetpassword.errors.required">Password is required</div>
						<div *ngIf="l.resetpassword.errors.minlength">Password must be at least 6 characters</div>
					</div>
				</div>
				<div class="form-group">
					<input type="password" placeholder="Enter Confirm Password" formControlName="resetpasswordconfirm"
						class="form-control" />
					<div class="errorClass" *ngIf="submitted && l.resetpasswordconfirm.errors">
						<div *ngIf="l.resetpasswordconfirm.errors.required">Password is required</div>
						<div *ngIf="l.resetpasswordconfirm.errors.minlength">Password must be at least 6 characters
						</div>
					</div>
				</div>
				<button class="signinn mb-2 btn btn-primary w-50 mx-auto">Update Password</button>
				<a routerLink="/login" class="float-right text-center mt-3 d-block">Login</a>
			</form>
		</div>
	</div>
</div>
