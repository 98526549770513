import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  constructor(private http: HttpClient) { }

  
	fetchUser(country) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/countrywise?country=${country}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	fetchUser1() {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/user/countrywise`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
  fetchArtwork(country) {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/artwork/countrywise?country=${country}`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
	fetchArtwork1() {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json'
		});
		return this.http.get(`${environment.apiUrl}/artwork/countrywise`, {
			headers: headers
		})
			.pipe(
				tap(data => { return data }), catchError(error => {
					/*Swal.fire({
						icon: 'error',
						title: 'Invalid request',
						showConfirmButton: false,
						timer: 6000
					  });*/
					return throwError(error);
				})
			);
	}
}
