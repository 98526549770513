import { Component, OnInit } from '@angular/core';
import {FormBuilder,FormGroup,Validators,FormControl,FormArray} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../_services/token-storage.service';
import {environmentbasic} from '../../environments/environment-basic';
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private tokenStorage: TokenStorageService,
		private router:Router,
		private route: ActivatedRoute) { }
		
	
	resetform: FormGroup;
	id!: string;
    loading = false;
    submitted = false;
    otpactive = false;
	email: string | null = null;

	ngOnInit(): void {

		this.route.queryParams.subscribe(params => {
			this.email = params['email'];
			console.log('Email received in resetpassword component:', this.email);
		  });

		this.resetform = this.formBuilder.group({
			resetpassword: ['', [Validators.required, Validators.minLength(6)]],
		    resetpasswordconfirm: ['', [Validators.required, Validators.minLength(6)]]
		});
	}
	
	get l() { return this.resetform.controls; }
	
	onResetSubmit(){
		this.submitted = true;
		if (this.resetform.invalid) {
            return;
        }
		this.authService.resetpassword(this.resetform.value.resetpassword,this.resetform.value.resetpasswordconfirm,this.email).subscribe(
		response => {
			if(response['status']=='SUCCESS'){
				
				Swal.fire({
				  icon: 'success',
				  title: 'Password Reset is successfully.',
				  showConfirmButton: false,
				  timer: environmentbasic.timer
				});
				this.router.navigate(['/login']);
			}else{
				Swal.fire({
				  icon: 'error',
				  title: response['message'],
				  showConfirmButton: false,
				  timer: environmentbasic.timer
				});
			} 		  
		});
	}
}
