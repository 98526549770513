import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '../_services/home.service';

@Component({
  selector: 'app-privacy-polity',
  templateUrl: './privacy-polity.component.html',
  styleUrls: ['./privacy-polity.component.scss']
})
export class PrivacyPolityComponent implements OnInit {
  privacyContent: string | null = null;
	projectName: any
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private homeService: HomeService,
		private cd: ChangeDetectorRef) { }


	ngOnInit(): void {
		this.projectName = "Property"
		this.getprofession_details(this.projectName)
	}

  getprofession_details(projectName: string): void {
    this.homeService.getprofession_details(projectName).subscribe(
      (response: any) => {
        if (response.status === 'SUCCESS') {
          // Find the item with type 'TermandCondtion'
          const privacyPlicy = response.payload.find((item: any) => item.type === 'PrivacyPolicy');
          if (privacyPlicy) {
            this.privacyContent = privacyPlicy.content;
          } else {
            console.error('No PrivacyPolicy found in the response');
          }
        } else {
          console.error('Response status is not SUCCESS');
        }
      },
      (error) => {
        console.error('API call error', error);
      }
    );
  }

}
