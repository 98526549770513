<app-header></app-header>
<app-menu></app-menu><br>
<div *ngIf="listingData" class="p-3 listing-container" style="background-color: transparent !important;padding: 0px 0px 0px 0px !important;overflow: hidden !important;">
    <h1 class="listing-title p-3">{{ listingData.title }}</h1>

    <div class="listing-header">
        <!-- <div class="listing-image">
          <app-image-slide [slides]="Images"></app-image-slide>
          </div> -->

          <div class="listing-image">
            <ng-container *ngIf="Images.length > 0; else noImage">
              <app-image-slide [slides]="Images"></app-image-slide>
            </ng-container>
            <ng-template #noImage>
              <app-image-slide [slides]="['https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg']"></app-image-slide>
            </ng-template>
          </div>
          
        <div class="listing-details">
            <p class="listing-detail"><i class="fas fa-ruler-combined"></i> <strong>Area:</strong> {{ listingData.area }}</p>
            <p class="listing-detail">
                <i class="fas fa-bed"></i>
                <strong>Configuration:</strong> 
                {{ listingData.noOfBadroom || 0 }} Bedrooms, 
                {{ listingData.feature?.noOfBathroom || 0 }} Bathrooms,  
                {{ listingData.feature?.noOfBalconies || 0 }} Balconies
              </p>
              <p class="listing-detail"><i class="fas fa-ruler-combined"></i> <strong>Size:</strong>{{listingData?.feature?.size}}{{listingData?.feature?.sizeUnit}}</p>
              <p class="listing-detail"><i class="fas fa-money-bill-wave"></i> <strong>Price:</strong> {{ listingData.asking | formatNumber}}</p>
              <p class="listing-detail"><i class="fas fa-money-check-alt"></i> <strong>Price per {{listingData?.feature?.sizeUnit}}:</strong> ₹ {{total}}</p>
              <p class="listing-detail"><i class="fas fa-map-marker-alt"></i> <strong>Address:</strong> {{ listingData?.address }}</p>
              <p class="listing-detail"><i class="fas fa-building"></i> <strong>Total Floors:</strong> {{listingData?.feature?.totalFloors || 0}}</p>
              <p class="listing-detail"><i class="fas fa-calendar-alt"></i> <strong>Property Age:</strong> {{listingData?.feature?.propertyAge}} Year</p>
            
        </div>
    </div>
    <mat-divider></mat-divider>

    <div class="listing-description">
        <div class="listing-detail">
          <i class="fas fa-couch"></i> <strong>Furnishing:</strong> {{listingData?.feature?.furnishing}}
        </div>
        <div class="listing-detail">
          <i class="fas fa-compass"></i> <strong>Direction:</strong> {{listingData?.feature?.direction}}
        </div>
        <div class="listing-detail">
          <i class="fas fa-map-marker-alt"></i> <strong>Nearby Places:</strong> {{listingData?.feature?.placesNearby}}
        </div>
        <div class="listing-detail">
          <i class="fas fa-battery-full"></i> <strong>Power Backup:</strong> {{listingData?.feature?.powerBackup}}
        </div>
        <div class="listing-detail">
          <i class="fas fa-barcode"></i> <strong>Property Code:</strong> {{listingData?.propertyCode}}
        </div>
        <div class="listing-detail">
          <i class="fas fa-key"></i> <strong>Property Ownership:</strong> {{listingData?.feature?.propertyOwnership}}
        </div>
        <div class="listing-detail">
          <i class="fas fa-ruler-combined"></i> <strong>Size:</strong> {{listingData?.feature?.size}}{{listingData?.feature?.sizeUnit}}
        </div>
        <div class="listing-detail">
          <i class="fas fa-exchange-alt"></i> <strong>Transaction Type:</strong> {{listingData?.feature?.transactionType}}
        </div>

        <div class="listing-detail">
          <i class="fas fa-binoculars"></i> <strong>Over Looking:</strong> {{listingData?.feature?.overLooking}}
      </div>
      
      <div class="listing-detail">
          <i class="fas fa-hard-hat"></i> <strong>Construction:</strong> {{listingData?.feature?.construction}}
      </div>
      
      <div class="listing-detail">
          <i class="fas fa-ruler-combined"></i> <strong>Buildup Area:</strong> {{listingData?.feature?.buildupArea}}
      </div>
      
      <div class="listing-detail">
          <i class="fas fa-handshake"></i> <strong>Negotial:</strong> {{listingData?.feature?.negotiable}}
      </div>
      
      <div class="listing-detail">
          <i class="fas fa-dollar-sign"></i> <strong>Loan Available:</strong> {{listingData?.feature?.loanAvailable}}
      </div>
      
      </div>
      
    <mat-divider></mat-divider>
    <div >
        <h2>Description</h2>
        <p [innerHTML]="listingData.description | safeHtml"></p>
    </div>
    <mat-divider></mat-divider>

      <div class="row justify-content-center">
          <div class="col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div><h2>Owner Details</h2></div> 
              <i class="fa fa-user-circle" style="color: #004aad; font-size: 100px;"></i>
          </div>
          <div class="col-md-6 d-flex flex-column align-items-center justify-content-center">
              <div *ngIf="showowner">
                  <p><i class="fas fa-user p-2"></i> {{ listingData?.user?.fullName }}</p>
                  <p><i class="fas fa-map-marker-alt p-2"></i> {{ listingData?.cityName }}</p>
              </div>
              <button class=" custom-button" (click)="openContactDetailsDialog(listingData)">Contact Details</button>
          </div>
      </div>

  </div><br>
  
<app-footer></app-footer>