import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Country } from 'country-state-city';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import Swal from 'sweetalert2';
import { HomeService } from '../_services/home.service';
import { ProfileService } from '../_services/profile.service';
import { JobService } from '../_services/job.service';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {environmentbasic} from '../../environments/environment-basic';
@Component({
	selector: 'app-update-assignments',
	templateUrl: './update-assignments.component.html',
	styleUrls: ['./update-assignments.component.css']
})
export class UpdateAssignmentComponent implements OnInit {
	activetab = 'applied';
	jobDetailForm: FormGroup;
	dropdownSettings: IDropdownSettings = {};
	dropdownSettingsCountry: IDropdownSettings = {};
	countries: any = [];
	preferCountries: any = '';
	skills: any = [];
	selectedSkills = [];
	selectedCountries = [];
	supportiveDocumentList: string = '';
	supportiveDocumentListArray:any = [];
	url_map = new Map<string, string>();
	token = '';
	submit = false;
	submitted = false;
	usertoken: any = [];
	bannerName:string ='';
	constructor(
		private homeService: HomeService,
		private formBuilder: FormBuilder,
		private jobService: JobService,
		private tokenStorage: TokenStorageService,
		private route: ActivatedRoute,
		private http: HttpClient) {
		this.usertoken = JSON.parse(this.tokenStorage.getToken());
		if (this.usertoken && this.usertoken.access_token) {
			this.token = this.usertoken.access_token;
		}
	}

	page = 1;
	count = 0;
	tableSize = 4;
	assignments: any = [];
	userinfo: any = [];
	baseuser: any = [];
	jobReferenceId: string = '';
	jobId:string = '';
	supportiveDocumentLinks: any = [];
	assignment: any = {};
	assignmentData:any={};
	ngOnInit(): void {
		AOS.init();
		this.route.params.subscribe(val => {
			if (val && val.assignmentId) {
				this.jobReferenceId = val.assignmentId;
			}
		});
		this.userinfo = JSON.parse(this.tokenStorage.getUser());
		this.homeService.getSkills().subscribe(res => {
			if (res['status'] == 'SUCCESS') {
				if (res['payload']['skills'] && res['payload']['skills'].length > 0) {
					this.skills = res['payload']['skills'];
				}
			}

		});
		
		this.jobService.getJobDetail(this.jobReferenceId ,this.jobId).subscribe(
			response => {
				if (response['status'] == 'SUCCESS') {
					if (response['payload']) {
						this.assignment = response['payload'];
						console.log(this.assignment)
						this.bannerName = this.assignment.banner;
						if(this.assignment.preferCountries!=null){
							let countries=this.assignment.preferCountries.split(",");
						for(let i=0;i<countries.length;i++){
							if(countries[i].length>0){
								this.selectedCountries.push(countries[i]);
							}						
						}
						}
						if(this.assignment.supportedDocuments!=null)	{
							let links=this.assignment.supportedDocuments.split(",");
						for(let i=0;i<links.length;i++){
							if(links[i].length>0){
								this.supportiveDocumentListArray.push(links[i].substring(links[i].lastIndexOf('/')+1));
							}						
						}	

						}			
						if(this.assignment.skillIds!=null)	{
							let skillss=this.assignment.skillIds.split(",");
						for(let i=0;i<skillss.length;i++){
							if(skillss[i].length>0 && skillss[i]!=''){
								//alert(skillss[i]);
								this.selectedSkills.push(this.getById(this.skills,skillss[i]));
							}
							
						}

						}		
						
						this.jobDetailForm.patchValue(this.populateAssignmentFormData(this.assignment));
					}
				}
			});

		this.jobDetailForm = this.formBuilder.group({
			id: [''],
			title: ['', Validators.required],
			banner:[''],
			description: [''],
			selectedSkills: [''],
			expertLevel: [''],
			estimatedMinBudget: [''],
			estimatedMaxBudget: [''],
			estimatedProjectLength: [''],
			expectedCandidateAvailability: [''],
			projectType: [''],
			currency: [''],
			preferCountries: [''],
			expireDate:['', Validators.required],
			supportiveDocumentList: ['']
		});
		this.jobDetailForm.get('estimatedMinBudget').valueChanges.subscribe(val => {
			if (val!=null && val!='') {
				(this.jobDetailForm.get('estimatedMaxBudget').valueChanges).subscribe(val => {
					if (val!=null && val!='') {
					  this.jobDetailForm.controls['currency'].setValidators([Validators.required]);
					} else {
					  this.jobDetailForm.controls['currency'].clearValidators();
					}
					this.jobDetailForm.controls['currency'].updateValueAndValidity();
				  });
			} else {
			  this.jobDetailForm.controls['currency'].clearValidators();
			}
			this.jobDetailForm.controls['currency'].updateValueAndValidity();
		  });


		this.dropdownSettings = {
			singleSelection: false,
			idField: 'id',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 10,
			enableCheckAll: false,
			allowSearchFilter: true
		};

		this.dropdownSettingsCountry = {
			singleSelection: false,
			idField: 'name',
			textField: 'name',
			selectAllText: 'Select All',
			unSelectAllText: 'UnSelect All',
			itemsShowLimit: 10,
			enableCheckAll: false,
			allowSearchFilter: true
		};

		this.countries = Country.getAllCountries();
		
	}

	populateAssignmentFormData(assignmentDb){
		this.assignmentData.id=assignmentDb.id;
		this.assignmentData.title=assignmentDb.title;
		this.assignmentData.description=assignmentDb.description;
		this.assignmentData.expertLevel=assignmentDb.expertLevel;
		this.assignmentData.estimatedMinBudget=assignmentDb.minBudget;
		this.assignmentData.estimatedMaxBudget=assignmentDb.maxBudget;
		this.assignmentData.estimatedProjectLength=assignmentDb.estimatedProjectLength;
		this.assignmentData.expectedCandidateAvailability=assignmentDb.expectedWeeklyWorkingHours;
		this.assignmentData.projectType=assignmentDb.projectType;
		this.assignmentData.selectedSkills=this.selectedSkills;
		this.assignmentData.currency=assignmentDb.currency;
		this.assignmentData.preferCountries = this.selectedCountries;
		this.assignmentData.expireDate = assignmentDb.expireDate;
		this.assignmentData.supportiveDocumentList = this.supportiveDocumentListArray;
		return this.assignmentData;
	}
	getById(skills,id){
		for (var i = 0; i < skills.length; i++) {
			if (skills[i]['id'] == id) {
				return({'id': id,'name': skills[i]['name']});
			}
		}
		return {};
	}
	get f() { return this.jobDetailForm.controls; }
	onJobDetailSubmit(submit: boolean) {
		this.submit = submit;
		this.submitted = true;
		if (!this.submit) {
			return;
		}

		if (this.jobDetailForm.invalid) {
			return;
		}
		let jobData: any = [];
		let updateJobRequest: any = {};
		jobData = this.jobDetailForm.value;
		updateJobRequest.title = jobData.title;
		updateJobRequest.description = jobData.description;
		updateJobRequest.expertLevel = jobData.expertLevel;
		updateJobRequest.minBudget = jobData.estimatedMinBudget;
		updateJobRequest.maxBudget = jobData.estimatedMaxBudget;
		updateJobRequest.estimatedProjectLength = jobData.estimatedProjectLength;
		updateJobRequest.expectedWeeklyWorkingHours = jobData.expectedCandidateAvailability;
		updateJobRequest.projectType = jobData.projectType;
		updateJobRequest.banner = this.bannerName;
		
		if (jobData.selectedSkills.length > 0){
			updateJobRequest.skillIds = [...new Set(jobData.selectedSkills.map(item => item.id))].toString();
		}
		
		if (jobData.preferCountries.length > 0) {
			updateJobRequest.preferCountries = [...new Set(jobData.preferCountries.map(item => item.name))].toString();
			updateJobRequest.isLocationSpecific = 1;
		} else {
			updateJobRequest.isLocationSpecific = 0;
		}
		updateJobRequest.client = { "id": null };
		updateJobRequest.client.id = this.userinfo.id;
		updateJobRequest.expireDate = jobData.expireDate;
		updateJobRequest.supportedDocuments = this.supportiveDocumentList;
		updateJobRequest.referenceJobId = this.jobReferenceId;
		this.jobService.updateJob(updateJobRequest).subscribe(
			response => {
				if (response['status'] == 'SUCCESS') {

					Swal.fire({
						icon: 'success',
						title: 'Job updateed successfully.',
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
					//window.location.reload();
				} else {
					Swal.fire({
						icon: 'error',
						title: response['message'],
						showConfirmButton: false,
						timer: environmentbasic.timer
					});
				}
			});
		
	}


	updateUserSkills(items: any) {
		this.selectedSkills.push(items);
	}

	removeUserSkills(items: any) {
		this.selectedSkills.forEach((skill, index) => {
			if (skill == items) {
				this.selectedSkills.splice(index, 1);
			}
		});

	}

	updateCountry(items: any) {

		this.preferCountries.push(items);
	}

	removeCountry(items: any) {
		this.preferCountries.forEach((country, index) => {
			if (country == items) {
				this.preferCountries.splice(index, 1);
			}
		});
	}

	uploadFile(event, purpose) {
		let selectedFiles = event.target.files;
		this.baseuser = this.userinfo;
		if (selectedFiles && selectedFiles.length) {
			for (let i = 0; i < selectedFiles.length; i++) {
				var mimeType =selectedFiles[i].type;
				this.getMediaMetaData(purpose, mimeType, selectedFiles[i]).subscribe(res => {
				});
			}
		}
	}

	getMediaMetaData(purpose, contentType, file) {

		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});

		return this.http.get(`${environment.apiUrl}/media/presigned?purpose=${purpose}&contentType=${contentType}`, {
			headers: headers
		})
			.pipe(
				concatMap(
					(res) =>
						this.s3UploadCall(res['payload']['preSignedUrl'], res['payload']['key'], file, contentType)));
	}
	s3UploadCall(preSignedUrl, key, file, contentType) {
		this.supportiveDocumentList = this.supportiveDocumentList + key + ','
		this.supportiveDocumentListArray.push(key);
		this.url_map.set(file.name, key);
		const headersPic = new HttpHeaders({
			'Content-Type': contentType
		});
		return this.http.put(preSignedUrl, file, { headers: headersPic });
	}
	uploadBanner(event, purpose){
		let selectedFiles = event.target.files;
		this.baseuser = this.userinfo;
		if (selectedFiles && selectedFiles.length) {
			for (let i = 0; i < selectedFiles.length; i++) {
				var mimeType =selectedFiles[i].type;
				this.getMediaMetaData1(purpose, mimeType, selectedFiles[i]).subscribe(res => {
				});
			}
		}

	}
	getMediaMetaData1(purpose, contentType, file) {
		const headers = new HttpHeaders({
			'Authorization': 'Basic ' + this.token,
			'Content-Type': 'application/json'
		});

		return this.http.get(`${environment.apiUrl}/media/presigned?purpose=${purpose}&contentType=${contentType}`, {
			headers: headers
		})
			.pipe(
				concatMap(
					(res) =>
						this.s3UploadCall1(res['payload']['preSignedUrl'], res['payload']['key'], file, contentType)));
	}
	s3UploadCall1(preSignedUrl, key, file, contentType) {
		this.bannerName = key;
		this.url_map.set(file.name, key);
		const headersPic = new HttpHeaders({
			'Content-Type': contentType
		});
		return this.http.put(preSignedUrl, file, { headers: headersPic });

	}
}
