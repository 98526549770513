<div class="container-fluid">
  <div class="contain {{swapclass}}" id="contain">
    <div class="form-contain sign-up-contain">
      <form [formGroup]="regform" (ngSubmit)="onSubmit()" class="account account1 reg{{registrationactive}}">
        <a class="navbar-brand mr-0 mb-4" routerLink="/">
          <img src="../../assets/images/logo45.png" alt="" class="onlylogo" >
        </a>
        <h2 class="createaccount">Create Account</h2>
        
        <div class="row">
          <div class="col-sm-12 col-12">
            <input type="text" placeholder="Full Name" formControlName="fullName" />
            <div class="errorClass" *ngIf="submitted && f.fullName.errors">
              <div *ngIf="f.fullName.errors.required">Full Name is required</div>
              <!-- <div *ngIf="f.fullName.errors.minlength">Full Name must be at least 8 characters</div> -->
              <!-- <div *ngIf="f.fullName.errors.pattern">Full Name must contain alphanumeric characters</div> -->
              <div>{{ this.uservalid }}</div>
            </div>
          </div>
      
          <div class="col-sm-12 col-12">
            <!-- <input type="email" placeholder="Email" formControlName="email" (blur)="onEmailInputBlur($event)" /> -->
            <input
            type="email"
            placeholder="Email"
            formControlName="email"
            #emailInput
            (blur)="onEmailInputBlur(emailInput.value)"
          />
          


            <div class="errorClass" *ngIf="submitted && f.email.errors">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
      
          <div class="col-sm-12 col-12">
            <input type="text" placeholder="Mobile Number" formControlName="mobileNumber" />
            <div class="errorClass" *ngIf="submitted && f.mobileNumber.errors">
              <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required</div>
              <div *ngIf="f.mobileNumber.errors.pattern">Mobile Number must be valid</div>
            </div>
          </div>
      
          <div class="col-sm-12 col-12">
            <input type="password" placeholder="Password" formControlName="password" />
            <div class="errorClass" *ngIf="submitted && f.password.errors">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
            </div>
          </div>
      
          <div class="col-sm-12 col-12 user-type-container">
            <label class="main-label">Are You</label>
            <div class="radio-group">
              <label>
                <input type="radio" formControlName="userType" [value]="{ individual: true, builder: false, agent: false }" /> Individual
              </label>
              <label>
                <input type="radio" formControlName="userType" [value]="{ individual: false, builder: true, agent: false }" /> Builder
              </label>
              <label>
                <input type="radio" formControlName="userType" [value]="{ individual: false, builder: false, agent: true }" /> Agent
              </label>
            </div>
            <div class="errorClass" *ngIf="submitted && regform.errors?.userTypeRequired">
              <div>User Type is required</div>
            </div>
          </div>
        </div>
        <button class="signinn">Sign Up</button>
        <div class="mobile-only" id="mobileSignIn" (click)="signInSwap()">Sign In</div>
      </form>
      <form [formGroup]="otpform" (ngSubmit)="onSubmitOtp()" class="account otp{{otpactive}}">
        <h3>Verify OTP</h3>
        <!--div class="social-contain">
          <a href="javascript:;" (click)="signInWithFB()" class="social"><i class="fa fa-facebook"
              aria-hidden="true"></i></a>
          <a href="javascript:;" (click)="signInWithGoogle()" class="social"><i class="fa fa-google"
              aria-hidden="true"></i></a>
        </div-->
        <span>Please check your email, we have sending you a 6 digits code.</span>
        <input type="text" placeholder="Enter OTP" formControlName="otp" />
        <div class="errorClass" *ngIf="submitted && g.otp.errors">
          <div *ngIf="g.otp.errors.required">Otp is required</div>
          <div *ngIf="g.otp.errors.minlength">Otp must be at least 6 characters</div>
          <div>{{this.otpvalid}}</div>
        </div>


        <button type="submit" class="signinn">Verify OTP</button>
      </form>
    </div>
    <div class="form-contain sign-in-contain">
    
      <form [formGroup]="loginform" (ngSubmit)="onLoginSubmit()" class="account login{{aciveforgot}}">
       
        <a class="navbar-brand mr-0 mb-4" routerLink="/">
          <img src="../../assets/images/logo45.png" alt="" class="onlylogo" >
        </a>

        <h2 class="welcome-back">Welcome Back</h2>
        <!-- <span>Sign In</span> -->
        <!--div class="social-contain">
          <a href="javascript:;" (click)="signInWithFB()" class="social"><i class="fa fa-facebook"
              aria-hidden="true"></i></a>
          <a href="javascript:;" (click)="signInWithGoogle()" class="social"><i class="fa fa-google"
              aria-hidden="true"></i></a>
        </div>
        <span>or use your account</span-->
        <input type="text" placeholder="Enter Username or Email" formControlName="emailusernam" />
        <div class="errorClass" *ngIf="submitted && h.emailusernam.errors">
          <div *ngIf="h.emailusernam.errors.required">Username is required</div>
          <div *ngIf="h.emailusernam.errors.minlength">Username must be at least 8 characters</div>
        </div>
        <input type="password" placeholder="Password" formControlName="loginpassword" />
        <div class="errorClass" *ngIf="submitted && h.loginpassword.errors">
          <div *ngIf="h.loginpassword.errors.required">Password is required</div>
          <div *ngIf="h.loginpassword.errors.minlength">Password must be at least 6 characters</div>
        </div>
        <button type="submit" class="signinn  mb-2 bsk" style="margin-top: 20px;">Sign In</button>
        <!-- <a href="javascript:;" (click)="forgotSwitch()" style="color: #004aad; margin-left: 7pc; margin-top: 13px;">Forgot your password?</a> -->
        <a href="javascript:;" (click)="forgotSwitch()" class="forgot-password-link">Forgot your password?</a>


        <div class="mobile-only" id="mobileSignUp" (click)="signUpSwap()">Sign Up</div>
      </form>
      <form [formGroup]="forgotform" (ngSubmit)="onForgotSubmit()" class="account fotgot{{aciveforgot}}">
        <h3 class="txt">Forgot Password</h3>
        <!-- <div class="social-contain">
          <a href="javascript:;" (click)="signInWithFB()" class="social"><i class="fa fa-facebook"
              aria-hidden="true"></i></a>
          <a href="javascript:;" (click)="signInWithGoogle()" class="social"><i class="fa fa-google"
              aria-hidden="true"></i></a>
        </div> -->
        <!-- <span>or use your account</span> -->
        <input type="email" placeholder="Email" formControlName="forgotemail" />
        <div class="errorClass" *ngIf="submitted && k.forgotemail.errors">
          <div *ngIf="k.forgotemail.errors.required">Email is required</div>
          <div *ngIf="k.forgotemail.errors.email">Email must be a valid email address</div>
        </div>
        <button type="submit" class="signinn mb-2"  style="margin-top: 10px;">Confirm Email</button>
        <a href="javascript:;" (click)="loginSwitch()" class="forgot-password-link1" style="margin-top: 20px;">Sign In</a>
      </form>

    </div>
    <div class="overlay-contain">
      <div class="overlay">
        <div class="overlay-panel overlay-left">
          <h3>Welcome Back!</h3>
          <p>To keep connected with us please login with your account</p><br>
          <button class="ghost" id="signIn" (click)="signInSwap()">Sign In</button>
        </div>
        <div class="overlay-panel overlay-right">
          <h1>Hello, Friend!</h1>
          <p>Enter your details and start your journey with us</p><br>
          <button class="ghost" id="signUp" (click)="signUpSwap()">Sign Up</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" class="loader"></div> 