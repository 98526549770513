<div class="row mt-5">
    <div class="col-md-12 mx-auto">
        <div class="row map-text">
            <div class="col-md-8">
                <h2>Arwork Map</h2>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <ul id="accordion" class="accordion" style="z-index: 999 !important;">
                        <li>

                            <ng-multiselect-dropdown [placeholder]="'All Countries'" [settings]="dropdownSettings"
                                [data]="countries" [(ngModel)]="selectedCountry" (onSelect)="changeCountry($event)"
                                (onDeSelect)="removeCountry($event)">
                            </ng-multiselect-dropdown>
                        </li>
                    </ul>
                    <!--select id="country" placeholder="Country" (change)="changeCountry($event.target)">
                        <option [ngValue]="null">Choose your country</option>
                        <option *ngFor="let country of countries" value="{{country.isoCode}}">
                            {{country.name}}
                        </option>
                    </select-->

                </div>


            </div>
        </div>
        <div>
            <div id="map"></div>
        </div>
    </div>
</div>
<div *ngIf="loading" class="loader"></div>