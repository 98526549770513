<mat-dialog-content>
    <div mat-dialog-title>
      <label class="mb-3 col-12">Verify OTP<span class="text-danger"></span></label>
    </div>
    <div mat-dialog-content class="text-center" style="border-radius: 25px;">
      <div class="spinner"></div>
      <div  id="otp"class="form-group">
        <ng-otp-input (onInputChange)="onOtpChange($event)"[config]="{length:6}"></ng-otp-input>
      </div>
      <br>
      <div class="button-container">
        <button mat-raised-button color="primary" (click)="verifyOtp()">Verify OTP</button>
      </div>
    </div>
  </mat-dialog-content>
  