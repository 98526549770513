import {
  Component,
  HostListener,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { HomeService } from '../_services/home.service';
import Swal from 'sweetalert2';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import { EmbedVideoService } from 'ngx-embed-video';
import { filter } from 'rxjs/operators';
import { ProfileService } from '../_services/profile.service';
import { AuthService } from '../_services/auth.service';
import { environment } from 'src/environments/environment';
import { HeaderSearchService } from '../_services/header-search.service';
import { LoadingService } from '../_services/loading.service';
import { AddPropertyComponent } from '../add-property/add-property.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-property',
  templateUrl: './home-property.component.html',
  styleUrls: ['./home-property.component.scss'],
})
export class HomePropertyComponent implements OnInit {
  @ViewChild('searchkey') searchInput: ElementRef;
  private subscription: Subscription = new Subscription();

  public loading = true;
  artslist: any = [];
  artslist1: any = [];
  artslist2: any = [];
  artslist3: any = [];
  artslist4: any = [];
  listings: any[] = [];
  listingsData: any[] = [];
  page: number = 1;
  limit: number = 10;
  sortactivetab = 'trending';
  mediums: any = [];
  userinfo: any = [];
  userPortfolio: any = [];
  customizeBackgroundContents: any = [];
  categories: any = [];
  searchkey: any = [];
  checkedcat: any = [];
  checkedmedium: any = [];
  selectQueryType:any;
  count = 0;
  tableSize = 28;
  dropdownSettings: IDropdownSettings = {};
  dropdownSettingsmedium: IDropdownSettings = {};
  selectedCategories = [] as any;
  selectedCategoryIds = [] as any;
  selectedMediums = [] as any;
  selectedMediumIds = [] as any;
  artParama: any = {
    artWorkCategories: [],
    limit: 28,
    mediumIds: [],
    offset: 0,
    searchString: '',
    sortCriteria: 'trending',
  };
  btnStyle = 'tablinks ';
  isLoggedIn = false;
  defaultThumbnail = '../assets/images/video.png';
  defaultThumbnailAudio = '../assets/images/audio.jpeg';
  maxCategoryMessage: boolean = false;
  searchResultMessage: boolean = false;
  maxMediumMessage: boolean = false;
  usertoken: any = [];
  searchValue: any = '';
  socialOrigin: any;
  useremail: any;
  payload: any;
  searchData: string = '';
  dropdownOpen = false;
  currentIndex: number = 0;

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: '',
    prevArrow: '',
    dots: false,
    infinite: true,
  };
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  selectOption(option: string) {
    console.log(`Selected option: ${option}`);
    this.dropdownOpen = false;
  }

  constructor(
    private homeService: HomeService,
    private tokenStorage: TokenStorageService,
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private embedService: EmbedVideoService,
    private router: Router,
    private searchService: HeaderSearchService,
    private loadingService: LoadingService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    console.log("HomePropertyComponent initialized");
  
    const queryData = localStorage.getItem("search_query");
    const queryType=localStorage.getItem('search_type');
    this.selectQueryType=queryType;
    console.log(this.selectQueryType);
    
  
    // Subscribe to search data updates
    this.searchService.searchData$.subscribe(data => {
      console.log('Data received from searchService:', data); // Debug log
      this.searchData = data;
      localStorage.setItem("search_query", data);
      if (data) {
        this.performSearch(data);
      } else {
        this.loadListings();
      }
    });
    
      
    if (queryData) {
      // Handle queryData if available
      this.performSearch(queryData);
    } else {
      // Handle case where no queryData is available
      this.loadListings();
    }
  }
  

  ngOnDestroy(): void {
    console.log('HomePropertyComponent destroyed');
    localStorage.removeItem('search_query');
    localStorage.removeItem('search_type');
    this.subscription.unsubscribe();
  }
  ngAfterViewInit(): void {
    // Manually trigger change detection if needed
    this.cdr.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['someData']) {
      this.performSearch(this.searchValue);
    }
  }
  
  performSearch(query: string) {
    this.loadingService.show();

    this.searchValue = query;
    console.log('Search Query:', query);

    let formattedPayload: any = {};

    if (this.payload && this.payload.filterName && this.payload.filterValue) {
      formattedPayload = this.formatFilters(
        this.payload.filterName,
        this.payload.filterValue
      );
    } else {
      console.error('Payload is not properly initialized:', this.payload);
    }

    let filters:any={};
if(this.selectQueryType){
  filters = {
    filterConditions:
    [{ filterName: "type", filterValue: this.selectQueryType }],
    sortConditions: [],
    searchValue: this.searchValue,
    page: 0,
    size: 50,
  }

}
else{

  filters = {
    filterConditions:
      Object.keys(formattedPayload).length === 0 ? [] : [formattedPayload],
    sortConditions: [],
    searchValue: this.searchValue,
    page: 0,
    size: 50,
  }
}
    
    console.log('Filters:', filters);
    this.subscription.add(
    this.homeService.property(filters).subscribe(
      (response: any) => {
        this.loadingService.hide();

        if (response && response.payload && response.payload.items) {
          this.listingsData = response.payload.items;
          this.listings = response.payload.items;
          console.log('Listings:', this.listings); // Add this log
          this.cdr.detectChanges();
        
        } else {
          console.error('Unexpected data format:', response);
          this.listings = [];
        }
      },
      (error) => {
        this.loadingService.hide();
        console.error('Error loading listings:', error);
        this.listings = [];
      }
    )
  )
  }

  onPayloadReceived(payload: any) {
    this.payload = payload;
    console.log(payload);
    this.loadingService.show();

    let formattedPayload = this.formatFilters(
      this.payload.filterName,
      this.payload.filterValue
    );
    console.log(formattedPayload);

    const askingFilterIndex = formattedPayload.findIndex(
      (item) => item.filterName === 'asking'
    );

    if (askingFilterIndex !== -1) {
      const askingFilter = formattedPayload[askingFilterIndex];

      formattedPayload.splice(askingFilterIndex, 1);

      const [minValue, maxValue] = askingFilter.filterValue
        .split(' - ')
        .map((value) => value.trim());

      formattedPayload.push(
        {
          filterName: 'fromAsking',
          filterValue: minValue,
        },
        {
          filterName: 'toAsking',
          filterValue: maxValue,
        }
      );
    }

    if (this.payload.filterValue == '') {
      this.loadListings();
    } else {
      let filters: any = {
        filterConditions: formattedPayload,
        sortConditions: [],
      };
      filters['searchValue'] = this.searchValue;
      filters['page'] = 0;
      filters['size'] = 50;
      console.log(filters);
      this.homeService.property(filters).subscribe(
        (response: any) => {
          if (response && response.payload && response.payload.items) {
            this.loadingService.hide();
            if (this.listings.length === 0) {
              // this.router.navigate(['/notfound']);
            }
            this.listings = response.payload.items;

            console.log('Listings:', this.listings);
          } else {
            console.error('Unexpected data format:', response);
            this.listings = [];
          }
        },
        (error) => {
          this.loadingService.hide();

          console.error('Error loading listings:', error);
          this.listings = [];
        }
      );
    }

    // this.fetchListings(payload);
  }
  formatFilters(filterName, filterValue) {
    if (!Array.isArray(filterName) || !Array.isArray(filterValue)) {
      console.error(
        'filterName or filterValue is not an array or is undefined'
      );
      return [];
    }

    const resultMap = {};

    // Map filterValues to their corresponding filterNames
    filterValue.forEach((value, index) => {
      const name = filterName[index] || filterName[filterName.length - 1];

      if (!resultMap[name]) {
        resultMap[name] = value;
      } else {
        resultMap[name] += `, ${value}`;
      }
    });

    // Convert resultMap to resultArray
    const resultArray = Object.keys(resultMap).map((key) => ({
      filterName: key,
      filterValue: resultMap[key],
    }));

    console.log(resultArray);
    return resultArray;
  }

  loadListings() {
    this.loadingService.show();
    let filters: any = {
      filterConditions: [],
      sortConditions: [],
      searchValue: this.searchData,
      page: 0,
      size: 100,
    };

    this.homeService.property(filters).subscribe(
      (response: any) => {
        if (response && response.payload) {
          if (response.payload.items) {

            this.listings = response.payload.items.map((item: any) => {
              return {
                ...item,
                ...item.feature,
              };
            });
            this.loadingService.hide();
            console.log('Listings:', this.listings);
          } else {
            console.log(this.listings.length);
          }
        } else {
          console.error('Unexpected data format:', response);
          this.listings = null;
          this.loadingService.hide();

        }
        console.log(this.listings.length);
      },
      (error) => {
        console.error('Error loading listings:', error);
        this.loadingService.hide();

        this.listings = null;
      }
    );
  }

  searchArtLists(searchkey: string) {
    this.searchResultMessage = true;
    this.artParama['searchString'] = searchkey;
    this.artParama['offset'] = 0;

    this.homeService.getArts(this.artParama).subscribe((response) => {
      this.manageArtListResponse(response);
    });
  }
  showTopIcon = false; // Property to control icon visibility

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTopIcon = window.scrollY > 100; // Show icon if scrolled down more than 200px
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  addevent(e: any) {
    if (e.target.checked) {
      this.selectedMediumIds.push(e.target.value);
    } else {
      this.selectedMediumIds = this.selectedMediumIds.filter(
        (id) => id !== e.target.value
      );
    }
    console.log(this.selectedMediumIds);
    this.artParama['mediumIds'] = this.selectedMediumIds;
    this.artParama['offset'] = 0;

    this.homeService.getArts(this.artParama).subscribe((response) => {
      this.manageArtListResponse(response);
    });
  }

  manageArtListResponse(response: any) {
    if (response['status'] == 'SUCCESS') {
      this.artslist = response['payload']['userProjects'] || [];
      this.count = response['payload']['total'];
      this.tableSize = response['payload']['limit'];
      let mod1 = this.artslist.length % 4;
      let slice1 = Math.floor(this.artslist.length / 4);
      let slice2 = slice1 + slice1;
      let slice3 = slice2 + slice1;
      if (mod1 == 0) {
        this.artslist1 = this.artslist.slice(0, slice1);
        this.artslist2 = this.artslist.slice(slice1, slice2);
        this.artslist3 = this.artslist.slice(slice2, slice3);
        this.artslist4 = this.artslist.slice(slice3, this.artslist.length);
      } else if (mod1 == 1) {
        this.artslist1 = this.artslist.slice(0, slice1 + 1);
        this.artslist2 = this.artslist.slice(slice1 + 1, slice2 + 1);
        this.artslist3 = this.artslist.slice(slice2 + 1, slice3 + 1);
        this.artslist4 = this.artslist.slice(slice3 + 1, this.artslist.length);
      } else if (mod1 == 2) {
        this.artslist1 = this.artslist.slice(0, slice1 + 1);
        this.artslist2 = this.artslist.slice(slice1 + 1, slice2 + 1);
        this.artslist3 = this.artslist.slice(slice2 + 1, slice3 + 1);
        this.artslist4 = this.artslist.slice(slice3 + 1, this.artslist.length);
      } else if (mod1 == 3) {
        this.artslist1 = this.artslist.slice(0, slice1 + 1);
        this.artslist2 = this.artslist.slice(slice1 + 1, slice2 + 1);
        this.artslist3 = this.artslist.slice(slice2 + 1, slice3 + 1);
        this.artslist4 = this.artslist.slice(slice3 + 1, this.artslist.length);
      }
    }
    this.loading = false;
    this.searchResultMessage = false;
  }

  videoembed(url: string) {
    return this.embedService.embed(url);
  }
}
