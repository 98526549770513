import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopProjectService {
  private toplistSubject = new BehaviorSubject<any>(null);
  toplist$ = this.toplistSubject.asObservable();

  setToplist(toplist: any) {
    this.toplistSubject.next(toplist);
  }
}
