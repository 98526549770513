
<div class="page-container">
    <app-header></app-header>
    <app-menu></app-menu><br>
<div style="margin-top: 30px;">
    <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>
  </div>
    <div *ngIf="!IsLoading" class="card-container">
      <ng-container *ngFor="let card of recentList">
        <app-top-property-card-small [cardData]="card" class="card-item"></app-top-property-card-small>
      </ng-container>
    </div>
    
    <div *ngIf="IsLoading">Loading...</div>
    
    <app-footer></app-footer>
</div>