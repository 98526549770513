<app-menu></app-menu>
<section class="sponser mt-3">
  <div class="pt-5 pb-5 container">
    <h1 class="h4 mb-4">Job Detail</h1>
    <div class="row">
      <div class="col-12 col-sm-12 listbox-shad">
        <h5 class="text-left assignment_title" style="font-size: 1.2rem;">{{contract.jobAssignments.title}}</h5>
        <div class="row mt-3 listbox-shad">
          <div class="col-sm-3 col-12 text-left">
            <span class="sub-head">Posted at: </span>
            <small class="small-text">{{contract.jobAssignments.postDateTime | date :'MMM d, y'}}</small>
          </div>
          <div class="col-sm-3 col-12 text-left">
            <span class="sub-head">Project Type: </span>
            <small class="small-text">{{contract.jobAssignments.projectType}}</small>
          </div>
        </div>
        <div class="row mt-3 listbox-shad">
          <div class="col-12 text-left">
            <span class="sub-head">Description</span>
          </div>
          <div class="text-left inner_description ml-3 mr-4 mt-1"
            [innerHTML]=" (contract.jobAssignments.description.length>200)? (contract.jobAssignments.description | slice:0:200)+'..':(contract.jobAssignments.description)">
          </div>
        </div>
        <div class="col-sm-12 text-right">
          <span class="badge badge-light p-2"> <a
              routerLink="/assignment-details/{{contract.jobAssignments.referenceJobId}}">More...</a></span>
        </div>
        <div class="row mt-3 listbox-shad">
          <div class="col-sm-4 col-12 text-left">
            <span class="sub-head">Country</span>
            <br>
            <div class="mt-1">
              <small class="small-text">{{contract.client.client.country}}</small>
            </div>
          </div>
          <div class="col-sm-4 col-12 text-left">
            <span class="sub-head">City</span>
            <br>
            <div class="mt-1">
              <small class="small-text">{{contract.client.client.city}}</small>
            </div>
          </div>
          <div class="col-sm-4 col-12 text-left">
            <span class="sub-head">Prefer Countries</span>
            <br>
            <div class="mt-1">
              <small class="small-text"><i class="fa fa-globe"
                  aria-hidden="true"></i>{{contract.jobAssignments.preferCountries!=null?contract.jobAssignments.preferCountries:'Worldwide'}}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="sponser mt-3">
  <div class="pt-5 pb-5 container">
    <h1 class="h4 mb-4">Contract Detail</h1>
    <div class="row">
      <div class="col-12 col-sm-12 listbox-shad">
        <div class="head" [innerHTML]="contract.contractText"></div>
        <hr>
        <h1 class="h4 mb-4">Acceptance Criteria</h1>
        <div class="head" [innerHTML]="contract.acceptanceCriteria"></div>
        <hr>
        <div class="row mt-3">
          <div class="col-sm-3 col-12 text-left">
            <b>Expected Completion Date</b><br><small>{{contract.completionDate}}</small>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-sm-12 text-right">
            <button type="submit" class="btn btn-danger" (click)="prepareMilestone(releaseMilestoneTemplate)">Add
              Milestone</button>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>


<section class="sponser mt-3">
  <div class="pt-5 pb-5 container">
    <div class="row">
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th scope="col" width="5%">#</th>
            <th scope="col" width="80%">Milestone Detail</th>
            <th scope="col" width="15%">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let milestone of milestones | paginate : {itemsPerPage: tableSize,currentPage: page, totalItems: count};let i = index;">
            <th scope="row">{{milestone.milestoneNumber}}</th>
            <td>
              <div class="head" [innerHTML]="milestone.milestoneDetail"></div>
            </td>
            <td><button type="submit" class="btn btn-danger" (click)="submitMilestones(contract.id)">Submit
                Milestone</button></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
<section class="pagcent">
  <nav aria-label="Page navigation example">
    <pagination-controls [responsive]="true" previousLabel="Prev" nextLabel="Next"
      (pageChange)="onTableDataChange($event)">
    </pagination-controls>
  </nav>
</section>



<ng-template #releaseMilestoneTemplate>
  <div class="modal-header c-header">

    <h4 class="modal-title w-100">Milestone Details
      <button type="button" class="close pull-right d-block" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </h4>

  </div>

  <div class="modal-body c-content">
    <div class="contents">
      <form [formGroup]="milestoneDetailsForm" (ngSubmit)="addMilestones()">
        <div class="form-row">
          <div class="form-group">
            <label class="col-form-label">Miestone Number</label>
            <input type="number" class="form-control" id="milestoneNumber" formControlName="milestoneNumber"
              placeholder="Miestone Number">
          </div>
          <div class="form-group">
            <label class="col-form-label">Miestone Detail</label>
            <editor id="milestoneDetail" formControlName="milestoneDetail"
              apiKey="1vfmkaj6jrcqw1p3hgjhe8nkcurhowos3itff52g0u522g44" [init]="{
                  height: 200,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help'
                }">
            </editor>

          </div>
        </div>
        <div class="w-100 mt-2">
          <button type="submit" class="btn btn-danger mr-2 confirm-ti-submit">Submit</button>
          <button type="button" class="btn btn-light" aria-label="Close" (click)="modalRef.hide()">Cancel</button>
        </div>
      </form>
    </div>


  </div>

</ng-template>