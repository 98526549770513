<app-header></app-header>
<app-menu></app-menu><br>
<div class="master py-1 px-xxl-4 px-3 mb-5">

    <div class="page-heading d-lg-flex justify-content-lg-between align-items-lg-center mt-md-2 mb-2">
        <span class="mb-lg-0 mb-3 grid-headers-font-size" style="color: #004AAD;">My Inventory</span>
        <button class="custom-btn" (click)="navigateToAddInventory()">Add Inventory</button>
    </div>
    
    <div class="card-container" *ngIf="topList.length > 0">
        <ng-container *ngFor="let card of topList">
            <app-top-property-card
            [cardData]="card"
            [showEditDetailsButton]="false"
            [showViewDetailsButton]="false"
            [showInventoryButton]="false"
            [showEditInventoryButton]="true">
          </app-top-property-card>
        </ng-container>
      </div>
      <div *ngIf="topList.length === 0">
        <p>No projects available.</p>
      </div>

    

</div>
<app-footer></app-footer>