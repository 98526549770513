import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {
params:any;
ProjectId:any
  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    console.log( this.params);
    this.ProjectId=this.params?.data?.id
    console.log(this.ProjectId);
    
    
  }
  agInit(params: any): void {
    
    this.params=params;
  }
  navigateToAddInventory() {
    this.router.navigate(['/project-invetory'], { 
      queryParams: { projectId: this.ProjectId }
    });
  }
}
