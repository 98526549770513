
<div class="page-container">
  <app-header></app-header>
  <app-menu></app-menu><br>
  

  <!-- <app-project-common-filter></app-project-common-filter> -->
  <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>


  <div *ngIf="!IsLoading" class="card-container">
    <ng-container *ngFor="let card of topList">
      <app-top-property-card
    [cardData]="card"
    [showEditDetailsButton]="true"
    [showViewDetailsButton]="true"
    [showInventoryButton]="true"
    [showEditInventoryButton]="false">
  </app-top-property-card>
    </ng-container>
  </div>
  
  <div *ngIf="IsLoading">Loading...</div>
  
  <app-footer></app-footer>
</div>