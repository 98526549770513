<app-header></app-header><br>
<div class="row d-flex card">
    <div class="col-md-3">
        <img src="assets/images/Wild-Life.webp" class="img-fluid image ">
    </div>
    <div class="col-md-6">
        <img src="assets/images/house.jpg" class="img-fluid image ">
    </div>
    <div class="col-md-3">
        <img src="assets/images/Autumn.webp" class="img-fluid image ">
    </div>
</div>
<br><br>
<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-12 mb-3" *ngFor="let card of cards; let i = index">
            <div class="property-card1 d-flex p-2">
                <div class="flex-shrink-0">
                    <img [src]="card.imageUrl" alt="" class="img-fluid1" />
                </div>
                <div class="flex-grow-1 ms-3">
                    <h5 class="card-text" style="font-size: 25px;"><b>{{ card.sourceName }} - {{ card.pubDate | date
                            }}</b></h5>
                    <p class="card-text truncated-text">
                        {{ (card.description)}}
                    </p>

                    <a class="card-text" style="color: blue; cursor: pointer;" [href]="card.link" target="_blank">
                        READ FULL ARTICLE HERE
                    </a>

                </div>
            </div>
            <hr *ngIf="i < cards.length" style="width: 100%;" />
        </div>
    </div>
</div>
<app-footer></app-footer>