<app-header></app-header><br>
<!-- <div class="project-overview"> -->
  <!-- <div class="row"> -->
     <div class="col-12 first-container first-container12">
    <div class="listing-image">
      <ng-container *ngIf="Images.length > 0; else noImage">
        <app-image-slide [slides]="Images"></app-image-slide>
      </ng-container>
      <ng-template #noImage>
        <app-image-slide [slides]="['https://images.pexels.com/photos/106399/pexels-photo-106399.jpeg']"></app-image-slide>
      </ng-template>
    </div>
    </div>
    <div class="col-12 first-container">
      <mat-card class="project-overview">
        <mat-card-title class="project-title mb-3">
          {{ title }}
        </mat-card-title>

        <mat-card-content>
          <div class="project-details">
            <div class="detail">
              <mat-icon>crop_square</mat-icon>
              <span>Project </span>
              <strong>{{titleDeed}}</strong>
            </div>
            <div class="detail">
              <mat-icon>straighten</mat-icon>
              <span>Type</span>
              <strong>{{inventoryType}}</strong>
            </div>
            <div class="detail">
              <mat-icon>location_city</mat-icon>
              <span>Project Size</span>
              <strong> {{area}} | {{sizeOfProperty}}</strong>
            </div>
            <div class="detail">
              <mat-icon>calendar_today</mat-icon>
              <span>Soul Quality</span>
              <strong>{{soilQuality}}</strong>
            </div>
            <div class="detail">
              <mat-icon>price_change</mat-icon>
              <span>Visit - Location</span>
              <strong>{{physicalVisit}} | {{propertyLocation}}</strong>
            </div>
            <div class="detail">
              <mat-icon>event</mat-icon>
              <span>Possession Starts</span>
              <strong>{{pricing}} | {{size}}</strong>
            </div>
            <div class="detail">
              <mat-icon>domain</mat-icon>
              <span>Configurations</span>
              <strong>{{projectName}}</strong>
            </div>
            <div class="detail">
              <mat-icon>assignment</mat-icon>
              <span>Rera Approved && Id</span>
              <strong> {{reraApproved}} | {{reraCompliance}}</strong>
              <!-- <a class="rera-status" href="#">Check RERA Status</a> -->
            </div>
          </div>
        </mat-card-content>

        <mat-card-actions>
          <button mat-button class="share-button">
            <mat-icon>share</mat-icon> Share
          </button>
          <button mat-button class="save-button">
            <mat-icon>favorite_border</mat-icon> Save
          </button>
          <button mat-raised-button color="primary">
            Ask For Details
          </button>
        </mat-card-actions>
      </mat-card>
    </div>
  <!-- </div> -->
<!-- </div> -->



<div class="property-cards1 mb-3 mt-3">
  <div class="property-details">
    <div class="property-title">{{ title }}</div>
    
    <div class="property-location">
      {{ location }}, {{ cityName }}, {{ stateName }}
    </div>
  </div>

  <div class="property-pricing">
    <div class="property-price">
      {{ formatPrice(fromPrice) }} - {{ formatPrice(toPrice) }}  
    </div>

    <div class="price-notes">Price excludes maintenance, floor rise</div>
    <div class="mt-3">
      <button class="btn contact-seller-btn">Contact Sellers</button>
    </div>
  </div>
</div>

<!-- <div class="row col-md-12 justify-content-end" style="padding-left:8pc; padding-right:8pc;">
  <!-- <button class="contact-seller-btn" (click)="opencontactceller()">Contact Sellers</button> -->
  <!-- <button class="contact-seller-btn" >Contact Sellers</button> -->

<!-- </div>  -->

<div class="amenities-container">
  <h2>Project Amenities</h2>
  <mat-grid-list cols="4" rowHeight="100px" gutterSize="10px">
    <mat-grid-tile *ngFor="let amenity of displayedAmenities">
      <div class="amenity-item">
        <mat-icon class="amenity-icon">{{ amenity.icon }}</mat-icon>
        <span class="amenity-label">{{ amenity.label }}</span>
      </div>
    </mat-grid-tile>
    <mat-grid-tile *ngIf="!showAll && amenities.length > 8" [colspan]="1" [rowspan]="1">
      <div class="show-more-container" (click)="toggleShowMore()">
        <span class="show-more-text">+{{ amenities.length - 7 }} more</span>
        <mat-icon>expand_more</mat-icon>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
  <button *ngIf="showAll" class="show-less-button" mat-button (click)="toggleShowMore()">Show Less</button>
</div>

<div class="card custom-card">
  <div class="card-header">
    <h4 class="card-title">Project Specifications</h4>
  </div>
  <div class="card-body">
    <mat-expansion-panel [expanded]="isExpanded" class="custom-expansion-panel">
      <mat-expansion-panel-header>
        <div class="panel-header-title">Floor & Counter</div>
      </mat-expansion-panel-header>
      <mat-chip-list>
        <div class="row">
          <div class="col-md-4 specification-item">
            <div class="spec-title">Builder</div>
            <div>{{builder}}</div>
          </div>
          <div class="col-md-4 specification-item">
            <div class="spec-title">Outdoor</div>
            <div>{{outdoor}}</div>
          </div>
          <div class="col-md-4 specification-item">
            <div class="spec-title">SmartHomes</div>
            <div>{{smartHomes}}</div>
          </div>
          <div class="col-md-4 specification-item">
            <div class="spec-title">Infrastructure</div>
            <div>{{infrastructure}}</div>
          </div>
          <div class="col-md-4 specification-item">
            <div class="spec-title">HighEndKitchens</div>
            <div>{{highEndKitchens}}</div>
          </div>
          <div class="col-md-4 specification-item">
            <div class="spec-title">Floor Plan</div>
            <div>{{floorPlan}}</div>
          </div>
        </div>
        <!-- </div> -->
      </mat-chip-list>
    </mat-expansion-panel>

    <div class="specification-group">
      <h5>Fitting</h5>
      <div class="row">
        <div class="col-md-4 specification-item">
          <div class="spec-title">Description</div>
          <div style="font-size: 13px;">{{ stripHtmlTags(description) }}</div>
        </div>
        <div class="col-md-4 specification-item">
          <div class="spec-title">ApartmentPossession</div>
          <div style="font-size: 13px;">{{apartmentPossession}}</div>
        </div>
      </div>
    </div>

    <div class="specification-group">
      <h5>Wall & Ceiling</h5>
      <div class="row">
        <div class="col-md-4 specification-item">
          <div class="spec-title">SmartHomes</div>
          <div>{{smartHomes}}</div>
        </div>
        <div class="col-md-4 specification-item">
          <div class="spec-title">Infrastructure</div>
          <div>{{infrastructure}}</div>
        </div>
        <div class="col-md-4 specification-item">
          <div class="spec-title">HighEndKitchens</div>
          <div>{{highEndKitchens}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
<br>
<div class="card custom-card">
  <div class="card-header">
    <!-- <h4 class="card-title" (click)="opencontactceller()" (click)="opencontactceller()">Contact Sellers</h4> -->
    <h4 class="card-title" >Contact Sellers</h4>

  </div>
      <div class="row align-items-center justify-content-center" style="padding-left: 35px; padding-right: 35px;">
        <div class="col-md-6 d-flex justify-content-start">
          <div class="col-md-12 d-flex flex-column">
            <div style="font-weight: bold; font-size: 14px;">{{builder}}</div>
            <div style="font-size: 20px; font-weight: bold;">
              {{ formatPrice(fromPrice) }} - {{ formatPrice(toPrice) }} 
              <span style="font-size: 12px;"></span>
            </div>
          </div>
          
        </div>
        <div class="col-md-6 d-flex justify-content-end">
          <button class="btn-custom">Contact</button>
        </div>
      </div>
      <hr class="custom-hr"/>
      <div class="row align-items-center justify-content-center">
        <div class="col-md-12 d-flex justify-content-center">
          <!-- <button class="btn-custom" (click)="opencontactceller()">Contact Sellers</button> -->
          <button class="btn-custom">Contact Sellers</button>

        </div><br><br><br>
      </div>
      
    </div>

<br><br>
  <app-footer></app-footer>