<app-menu></app-menu>
<section class="">
  <div class="pt-5 pb-5 container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12  listbox-shad">
        <form class="card-sm">
          <div class="row no-gutters align-items-center">
            <div class="col-auto">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <!--end of col-->
            <div class="col">
              <input class="form-control form-control form-control-borderless" type="search" #searchkey
                placeholder="Search Jobs...">
              <!--mat-form-field>
                <input class="form-control form-control-lg form-control-borderless" type="search" placeholder=""
                  #searchkey matInput [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let skill of skills" [value]="skill.name">
                    {{skill.name}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field-->
            </div>
            <!--end of col-->
            <div class="col-auto">
              <button class="btn btn-danger" type="button" (click)="searchJob(searchkey)">Search</button>
            </div>
            <!--end of col-->
          </div>
        </form>
      </div>
    </div>
    <section class="gallery-section mt-3">
      <div class="row">
        <div class="col-sm-12 col-12 listbox-shad">
          <div class="listing-box p-3"
            *ngFor="let job of jobs | paginate : {itemsPerPage: tableSize,currentPage: page, totalItems: count};let i = index;">
            <a routerLink="/assignment-details/{{job.referenceJobId}}" class="text-center" style="font-size: 30px;font-weight: 600;background-color: red;color: white;width: 170px;padding: 3px;">{{job.title}}</a>
            <img src="{{job.banner}}" style="width: 100%;" class="d-block mt-2 img-fluid">

            <!--div class="description"
              [innerHTML]=" (job.description.length>200)? (job.description | slice:0:200)+'..':(job.description)">
            </div-->
            <div class="col-sm-12 mt-4">
              <span class="badge badge-light p-2"> <a
                  routerLink="/assignment-details/{{job.referenceJobId}}">More</a></span>
              <p id="slider_desc_toogler"> </p>
            </div>
            <p class="text-left  listbox-shad" *ngIf="job.skillIds.split(',') as skillTextList">
              <span class="badge badge-light p-2" style="margin-left: 10px;"
                *ngFor="let skillId of skillTextList">{{getSkillById(skillId)}}</span>
            </p>
            <hr>
            <button type="button" class="btn btn-sm btn-outline-danger float-right ml-3"
              routerLink="/apply-assignment/{{job.referenceJobId}}">Apply Job</button>
            <button type="button" class="btn btn-sm btn-danger float-right" (click)="saveJob(job.referenceJobId)">Save a
              Job</button>
          </div>
        </div>
      </div>

    </section>
    <section class="pagcent mt-3">
      <nav aria-label="Page navigation example">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </nav>
    </section>
  </div>
</section>