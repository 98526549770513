import { Component, EventEmitter, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { City, Country, State } from 'country-state-city';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { HomeService } from '../_services/home.service';
import { ProfileService } from '../_services/profile.service';
import { TokenStorageService } from '../_services/token-storage.service';
import { WorkExperienceService } from '../_services/workexperience.service';


@Component({
  selector: 'work-grid-btn-cell',
  templateUrl: './work-grid-btn-cell.component.html',
  styleUrls: ['./work-grid-btn-cell.component.scss']
})
export class WorkGridButtonCellComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();
  constructor(private modalService: BsModalService, private formBuilder: FormBuilder,
    private workExperienceService: WorkExperienceService,
    private tokenStorage: TokenStorageService) { }

  public params2: any;
  workExperienceForm: FormGroup;
  public modalRef: BsModalRef;
  userinfo: any = null;
  countries: any = [];
  selectedCountry: any = '';
  states: any = [];
  cities: any = [];
  years: number[] = [];

  agInit(params2: any): void {
    this.params2 = params2;
  }

  changeCountry(country: any) {
    this.states = State.getStatesOfCountry(country);
    this.selectedCountry = country;
  }

  changeState(state: any) {
    this.cities = City.getCitiesOfState(this.selectedCountry, state);
  }

  public openWorkExperienceUpdateModal(manageWorkExperienceTemplate: TemplateRef<any>) {
    this.modalRef = this.modalService.show(manageWorkExperienceTemplate);
  }

  saveUpdateWorkExperience() {
    let workAccountData = this.workExperienceForm.value;
    workAccountData.userProfile = { "id": this.params2.data.userProfile.id };
    workAccountData.isCurrentlyWorking=workAccountData.isCurrentlyWorking?1:0;
    this.workExperienceService.updateWorkExperience(this.userinfo.id, workAccountData).subscribe(res => {
      window.location.reload();
    });;
  }

  public deleteWorkExperience(id: any) {
    this.workExperienceService.deleteWorkExperience(this.userinfo.id, id).subscribe(res => {
      window.location.reload();
    });
  }


  ngOnInit() {
    for (let year = 2030; year >= 1950; year--) {
      this.years.push(year);
    }
    this.countries = Country.getAllCountries();
    this.cities = City.getCitiesOfCountry(this.params2.data.country);

    this.userinfo = JSON.parse(this.tokenStorage.getUser());
    this.workExperienceForm = this.formBuilder.group({
      id: [''],
      companyName: [''],
      position: [''],
      description: [''],
      city: [''],
      country: [''],
      isCurrentlyWorking: [''],
      endingIn: [''],
      startingFrom: [''],
      userProfile: ['']
    });
    this.workExperienceForm.controls.id.setValue(this.params2.data.id);
    this.workExperienceForm.controls.companyName.setValue(this.params2.data.companyName);
    this.workExperienceForm.controls.position.setValue(this.params2.data.position);
    this.workExperienceForm.controls.description.setValue(this.params2.data.description);
    this.workExperienceForm.controls.city.setValue(this.params2.data.city);
    this.workExperienceForm.controls.country.setValue(this.params2.data.country);
    this.workExperienceForm.controls.isCurrentlyWorking.setValue(this.params2.data.isCurrentlyWorking);
    this.workExperienceForm.controls.endingIn.setValue(this.params2.data.endingIn);
    this.workExperienceForm.controls.startingFrom.setValue(this.params2.data.startingFrom);
    //this.workAccountForm.setValue(this.params2);
  }

}
