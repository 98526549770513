<app-menu></app-menu>
<section class="">
  <div class="pt-5 pb-5 container">
    <div class="row">
      <div class="col-12 col-md-12 col-lg-12 listbox-shad" style="padding: 5px;">
        <form class="card-sm" (submit)="searchArtist(searchkey)">
          <div class="card-body row no-gutters align-items-center p-2">
            <div class="col-auto">
              <i class="fa fa-search" aria-hidden="true"></i>
            </div>
            <!--end of col-->
            <div class="col">
              <input class="form-control form-control form-control-borderless" type="search" #searchkey
                placeholder="Search artist by name, email..." (keyup)="searchArtist(searchkey)"
                (search)="searchArtist(searchkey)">
            </div>
            <!--end of col-->
            <div class="col-auto">

            </div>
            <!--end of col-->
          </div>
        </form>
      </div>
    </div>
    <section class="gallery-section mt-3">
      <div class="row">
        <div class="col-sm-12 col-12 listbox-shad">
          <div class="listing-box p-3"
            *ngFor="let expertProfile of expertProfiles | paginate : {itemsPerPage: tableSize,currentPage: page, totalItems: count};let i = index;">
            <div>
              <div class="avatar">
                <img alt="" src="{{expertProfile.profilePic!= null ? expertProfile.profilePic : profilePic}}">
              </div>
              <div style="margin-left: 95px;">
                <a routerLink="/profile/{{expertProfile.username}}/{{expertProfile.userid}}/portfolio">
                  <h4 class="text-left">{{expertProfile.firstName}} {{expertProfile.lastName}}</h4>
                </a>
                <div class="text-left" style="font-size: 14px;">{{expertProfile.professionHeadline}}</div>
                <small class="text-secondary text-left d-block">
                  {{expertProfile.city}} | {{expertProfile.state}}, {{expertProfile.country}}</small>
                <!--div class="head" [innerHTML]="expertProfile.aboutMe"></div-->
                <div class="text-left" *ngIf="expertProfile.skillNames == null">
                  <span>&nbsp;</span>
                </div>
                <div class="text-left" *ngIf="expertProfile.skillNames != null">
                  <span class="badge badge-light pt-1 pb-1 mr-2 mb-1"
                    *ngFor="let skillName of expertProfile.skillNames">{{skillName}}</span>
                </div>
              </div>
            </div>

            <div>
              <p class="text-left mt-1" *ngIf="!artistFollow.includes(convertToNumeric(expertProfile.userid))">
                <button style="font-size: 16px;" type="submit" class="btn btn-danger"
                  (click)="userFollowing(expertProfile.userid)">Follow</button>
                &nbsp;
                <a style="font-size: 16px;" routerLink="/message/{{expertProfile.firstName}}/{{expertProfile.userid}}"
                  class="btn btn-sm btn-primary pl-2"><i class="fa fa-comment-o" aria-hidden="true"></i> Connect</a>
              </p>
              <p style="font-size: 16px;" class="text-left mt-1"
                *ngIf="artistFollow.includes(convertToNumeric(expertProfile.userid))">
                <button type="submit" class="btn btn-danger" disabled>Following</button>
                &nbsp;
                <a style="font-size: 16px;" routerLink="/message/{{expertProfile.firstName}}/{{expertProfile.userid}}"
                  class="btn btn-sm btn-primary pl-2"><i class="fa fa-comment-o" aria-hidden="true"></i> Connect</a>
              </p>
            </div>
            <hr style="margin-top: 2rem; margin-bottom: 0;">
          </div>
        </div>
      </div>

    </section>
    <section class="pagcent mt-3">
      <nav aria-label="Page navigation example">
        <pagination-controls previousLabel="Prev" nextLabel="Next" (pageChange)="onTableDataChange($event)">
        </pagination-controls>
      </nav>
    </section>
  </div>
</section>