
<div class="page-container">
  <app-header></app-header>
  <app-menu></app-menu><br>
<div style="margin-top: 30px;">
  <app-project-common-filter (searchTermChange)="onSearchTermChange($event)"></app-project-common-filter>
</div>

  <div *ngIf="!IsLoading" class="card-container" style="cursor: pointer;">
    <ng-container *ngFor="let card of topList">
      <app-top-property-card
    [cardData]="card"
    [showEditDetailsButton]="false"
    [showViewDetailsButton]="false"
    [showInventoryButton]="false"
    [showEditInventoryButton]="false">
  </app-top-property-card>
    </ng-container>
  </div>
  
  <div *ngIf="IsLoading">Loading...</div>
  
  <app-footer></app-footer>
</div>