
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderSearchService {
  private searchData = new Subject<string>();
  searchData$ = this.searchData.asObservable();

  setSearchData(data: string) {
    this.searchData.next(data);
  }
}
