import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
type Card = {
  imageUrl: string;
  title: string;
  subtitle: string;
  details: string;
  location: string;
  price: string;
  sourceName: string;
  pubDate: string;
  description: string;
  link:any;
};
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  @Input() cardData: any;
 
  cards: Card[] = [];

  card: { description: string | null } = { description: null };
  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchData();
    this.card.description = 'Your description text goes here...';
  }



  

  // cards = [
  //   {
  //     image:
  //       'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
  //     title: 'Anantam',
  //     subtitle: 'By Anuradha Ramamirtham | August 23, 2024',
  //     details: 'All about intangible assets',
  //     location:
  //       'A well-equipped kitchen is key to making cooking easier and more enjoyable.The right appliances can save you time and make your kitchen work better. They also add style and convenience to your space. In this article, you’ll find 10 essential appliances that will help you turn your kitchen into a more efficient and modern place to cook......',
  //     price: 'READ FULL ARTICLE',
  //   },
  //   {
  //     image:
  //       'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
  //     title: 'Anantam',
  //     subtitle: 'By Anuradha Ramamirtham | August 23, 2024',
  //     details: 'All about intangible assets',
  //     location:
  //       'A well-equipped kitchen is key to making cooking easier and more enjoyable.The right appliances can save you time and make your kitchen work better. They also add style and convenience to your space. In this article, you’ll find 10 essential appliances that will help you turn your kitchen into a more efficient and modern place to cook......',
  //     price: 'READ FULL ARTICLE',
  //   },
  //   {
  //     image:
  //       'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
  //     title: 'Anantam',
  //     subtitle: 'By Anuradha Ramamirtham | August 23, 2024',
  //     details: 'All about intangible assets',
  //     location:
  //       'A well-equipped kitchen is key to making cooking easier and more enjoyable.The right appliances can save you time and make your kitchen work better. They also add style and convenience to your space. In this article, you’ll find 10 essential appliances that will help you turn your kitchen into a more efficient and modern place to cook......',
  //     price: 'READ FULL ARTICLE',
  //   },
  //   {
  //     image:
  //       'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
  //     title: 'Anantam',
  //     subtitle: 'By Anuradha Ramamirtham | August 23, 2024',
  //     details: 'All about intangible assets',
  //     location:
  //       'A well-equipped kitchen is key to making cooking easier and more enjoyable.The right appliances can save you time and make your kitchen work better. They also add style and convenience to your space. In this article, you’ll find 10 essential appliances that will help you turn your kitchen into a more efficient and modern place to cook......',
  //     price: 'READ FULL ARTICLE',
  //   },
  //   {
  //     image:
  //       'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
  //     title: 'Anantam',
  //     subtitle: 'By Anuradha Ramamirtham | August 23, 2024',
  //     details: 'All about intangible assets',
  //     location:
  //       'A well-equipped kitchen is key to making cooking easier and more enjoyable.The right appliances can save you time and make your kitchen work better. They also add style and convenience to your space. In this article, you’ll find 10 essential appliances that will help you turn your kitchen into a more efficient and modern place to cook......',
  //     price: 'READ FULL ARTICLE',
  //   },
  //   {
  //     image:
  //       'https://storage.googleapis.com/generativeartbucket/UserGenerations/cristian/output-81b681cf-3b17-4631-ac59-eea7061ad91e-2024-07-31-01-25-13-4_optimize.png',
  //     title: 'Anantam',
  //     subtitle: 'By Anuradha Ramamirtham | August 23, 2024',
  //     details: 'All about intangible assets',
  //     location:
  //       'A well-equipped kitchen is key to making cooking easier and more enjoyable.The right appliances can save you time and make your kitchen work better. They also add style and convenience to your space. In this article, you’ll find 10 essential appliances that will help you turn your kitchen into a more efficient and modern place to cook......',
  //     price: 'READ FULL ARTICLE',
  //   },
  // ];


  // Method to truncate text to 300 words
  truncateText(text: string, wordLimit: number = 300): string {
    if (!text) return '';
    const words = text.split(' ');
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(' ') + '...';
  }

  fetchData() {
    const url = 'https://newsdata.io/api/1/news?apikey=pub_515574f1ecfae471ae7d04b38a0dee48ca6df&q=property&country=in&language=en&category=top';
    
    this.http.get(url).subscribe(
      (response: any) => {
        console.log("Response data:", response);
  
        // Assuming the data structure is { results: [...] }
        const results = response.results;
  
        // Extracting the desired fields from each result
        this.cards = results.map((item: any) => ({
          imageUrl: item.image_url,
          description: item.description,
          pubDate: item.pubDate,
          sourceName: item.source_name,
          link: item.link, // Ensure this field is populated
        }));
  
        console.log("Extracted Data:", this.cards);
      },
      (error) => {
        console.error('Error fetching data', error);
      }
    );
  }
  
  
}
