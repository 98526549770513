import { Component, OnInit } from '@angular/core';
import {FormBuilder,FormGroup,Validators,FormControl,FormArray} from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import Swal from 'sweetalert2';
import { TokenStorageService } from '../_services/token-storage.service';
import * as AOS from 'aos';
import {environmentbasic} from '../../environments/environment-basic';
@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {
       
	otp: string = '';
	number: any;
	isMessage: boolean = false;
	message: string = '';
	status: boolean = false;
	email: string;
	
	constructor(
		private formBuilder: FormBuilder,
		private authService: AuthService,
		private tokenStorage: TokenStorageService,
		private route: ActivatedRoute,
		private router:Router) { }
		
	
	resetform: FormGroup;
	id!: string;
    loading = false;
    submitted = false;
    otpactive = false;
   	pramaval:any=[];
   	useremail='';

	ngOnInit(): void {
		AOS.init();
		this.route.params.subscribe(val => {
			this.pramaval=val;
			if(this.pramaval && this.pramaval.email){
					this.useremail=this.pramaval.email;
			}else{
				this.router.navigate(['/login']);
			}
		});
		
		this.resetform = this.formBuilder.group({
			resetpassword: ['', [Validators.required, Validators.minLength(6)]],
		    resetpasswordconfirm: ['', [Validators.required, Validators.minLength(6)]]
		});
	}
	
	get l() { return this.resetform.controls; }
		
	onOtpChange(otp: string) {
		this.otp = otp;
		console.log(otp);
		
	  }

	onResetSubmit(){
		this.submitted = true;
		if (this.resetform.invalid) {
            return;
        }
		this.authService.resetpassword(this.resetform.value.resetpassword,this.resetform.value.resetpasswordconfirm,this.useremail).subscribe(
		response => {
			if(response['status']=='SUCCESS'){
				
				Swal.fire({
				  icon: 'success',
				  title: 'Password Reset is successfully.',
				  showConfirmButton: false,
				  timer: environmentbasic.timer
				});
				this.router.navigate(['/login']);
			}else{
				Swal.fire({
				  icon: 'error',
				  title: response['message'],
				  showConfirmButton: false,
				  timer: environmentbasic.timer
				});
			} 		  
		});
	}
	
}
